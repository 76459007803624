export const PageName = "User";
export const PageNameKey = "USERS";
export const StateName = "users";
export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const initialFilter = {
  filter: {},
  sortOrder: "asc", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 10,
};
export const Gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];
