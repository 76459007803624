export const PageName = "User Mood Tracker";
export const PageNameKey = "USER_MOOD_TRACKER";
export const StateName = "userMoodTrackers";

export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const initialFilter = {
  filter: {},
  sortOrder: "asc", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 10,
};
export const UserEmotionType = [
  { label: "Happy", value: "Happy" },
  { label: "Sad", value: "Sad" },
  { label: "Excited", value: "Excited" },
  { label: "Content", value: "Content" },
  { label: "Stressed", value: "Stressed" },
  { label: "Bored", value: "Bored" },
];
