/* eslint-disable no-script-url, jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { userRole } from "../../../constant";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    pageName,
    PageNameKey,
    openEditItemPage,
    openAddSubItemPage,
    openDeleteItemDialog,
    openContentPage,
    permissions,
    userRoles,
    showViewButton = false,
    ShowPlusButton = false,
  }
) => {
  console.log("PageNameKey ===>", PageNameKey);

  const hasPermission = (permissionKey) =>
    userRoles.includes(userRole.superAdmin) ||
    userRoles.includes(userRole.contentManager) ||
    userRoles.includes(userRole.contentAdmin) ||
    (permissions && permissions.includes(permissionKey));

  return (
    <>
      {hasPermission(`${PageNameKey}_EDIT`) && (
        <>
          <OverlayTrigger
            overlay={<Tooltip id="item-edit-tooltip">Edit {pageName}</Tooltip>}
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm "
              onClick={() => openEditItemPage(row.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>
          </OverlayTrigger>
          <> </>
        </>
      )}
      {hasPermission(`${PageNameKey}_ADD_SUB_DATA`) && ShowPlusButton && (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip id="item-edit-tooltip">Add Sub {pageName}</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => openAddSubItemPage(row.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-white font-weight-bolder h3">
                +
              </span>
            </a>
          </OverlayTrigger>
          <> </>
        </>
      )}
      {showViewButton && hasPermission(`${PageNameKey}_VIEW`) && (
        <>
          <OverlayTrigger
            overlay={<Tooltip id="item-view-tooltip">View {pageName}</Tooltip>}
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => openContentPage(row.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <img
                  style={{ width: "30px" }}
                  src={toAbsoluteUrl("/media/svg/icons/Communication/view.png")}
                />
              </span>
            </a>
          </OverlayTrigger>
          <> </>
        </>
      )}
      {hasPermission(`${PageNameKey}_DELETE`) && (
        <OverlayTrigger
          overlay={
            <Tooltip id="item-delete-tooltip">Delete {pageName}</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => openDeleteItemDialog(row.id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
    </>
  );
};
