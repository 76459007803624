/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Unauthorize from "./modules/Auth/pages/unauthorize";
import { userRole } from "../_component/constant";
export function Routes() {
  const history = useHistory();

  const { isAuthorized, userRoles, permissions } = useSelector(
    (state) => ({
      isAuthorized: state.auth.user != null,
      userRoles: state.auth.userRoles,
      permissions: state.auth.permissions,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (
      history.location.pathname !== "/validate-token" &&
      process.env.NODE_ENV === "production" &&
      !isAuthorized
    ) {
      history.push("/auth/login");
    } else if (
      history.location.pathname === "/validate-token" &&
      isAuthorized
    ) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  // useEffect(()=>{
  // },[permissions,userRoles])

  // console.log("isAuthorized", isAuthorized);

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      {process.env.NODE_ENV !== "production" && !isAuthorized && (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      )}
      <Route path="/unauthorize" component={Unauthorize} />
      {history.location.pathname !== "/validate-token" &&
        isAuthorized &&
        (userRoles.includes(userRole.superAdmin) ||
          userRoles.includes(userRole.contentManager) ||
          userRoles.includes(userRole.contentAdmin) ||
          userRoles.includes(userRole.organizationAdmin) ||
          permissions) &&
        (userRoles.includes(userRole.superAdmin) ||
        userRoles.includes(userRole.contentManager) ||
        userRoles.includes(userRole.contentAdmin) ||
        userRoles.includes(userRole.organizationAdmin) ||
        permissions.length > 0 ? (
          <Layout>
            <BasePage />
          </Layout>
        ) : (
          <Redirect to="/unauthorize" />
        ))}
    </Switch>
  );
}
