// eslint-disable no-script-url,jsx-a11y/anchor-is-valid /
import React from "react";
// import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// import UserProfilePage from "../../../../app/modules/UserProfile/UserProfilePage";
import { userRole } from "../../../constant";

export const CustomActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    pageName,
    PageNameKey,
    openCloneItemDialog,
    openEditItemPage,
    openAddSubItemPage,
    openContentPage,
    openViewUserProfile,
    openDeleteItemDialog,
    openRoleAssignmentPage,
    showCloneButton,
    permissions,
    userRoles,
    showViewButton = false,
    ShowPlusButton =false
  }
) => {

  return (
    <>
   
      {(userRoles.includes(userRole.superAdmin) ||
        (permissions && permissions.includes(`${PageNameKey}_EDIT`)))  &&  
          (
        <>
          <OverlayTrigger
            overlay={<Tooltip id="item-edit-tooltip">Edit {pageName}</Tooltip>}
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => openContentPage(row.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>
          </OverlayTrigger>
          <> </>
        </>
                  )}

{(userRoles.includes(userRole.superAdmin) ||
        (permissions && permissions.includes(`${PageNameKey}_ADD_SUB_DATA`)))&&ShowPlusButton && (
        <>
          <OverlayTrigger
            overlay={<Tooltip id="item-edit-tooltip">Add {pageName}</Tooltip>}
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => openAddSubItemPage(row.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Add-sub-componrnt.svg"
                  )}
                />
              </span>
            </a>
          </OverlayTrigger>
          <> </>
        </>
      )}

      {showViewButton &&
        (userRoles.includes(userRole.superAdmin) ||
          (permissions && permissions.includes(`${PageNameKey}_VIEW`))) && (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="item-edit-tooltip">View {pageName}</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                 onClick= {()=>openViewUserProfile(row.id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                  style={{width:"30px"}}
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/view.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <> </>
          </>
        )}
        
      {/* {showCloneButton &&
        (userRoles.includes(userRole.superAdmin) ||
          (permissions && permissions.includes(`${PageNameKey}_CLONE`))) && (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="item-edit-tooltip">Clone {pageName}</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => openCloneItemDialog(row)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Duplicate.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <> </>
          </>
        )} */}
      {(userRoles.includes(userRole.superAdmin) ||
        (permissions && permissions.includes(`${PageNameKey}_DELETE`))) && (
        <OverlayTrigger
          overlay={
            <Tooltip id="item-delete-tooltip">Delete {pageName}</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
            onClick={() => openDeleteItemDialog(row.id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
    </>
  );
};
