import React from "react";
import * as actions from "../_redux/actions";
import * as uiHelpers from "./ItemsUIHelpers";
import { ItemsList } from "../../../../_component/list/ItemsList";
import { sortCaret } from "../../../../_metronic/_helpers";

export default (props) => {
  return (
    <ItemsList
      {...props}
      actions={actions}
      uiHelpers={uiHelpers}
      showViewButton={true}
      actionsColumnFormatter="CustomActionsColumnFormatter"
      columns={[
        {
          dataField: "name",
          text: "Name",
          sort: true,
          sortCaret,
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
          sortCaret,
        },
        {
          dataField: "age",
          text: "Age",
          sort: true,
          sortCaret,
        },
        {
          dataField: "gender",
          text: "Gender",
          sort: true,
          sortCaret,
        },
        {
          dataField: "mobileNo",
          text: "Mobile No",
          sort: true,
          sortCaret,
        },
      ]}
    />
  );
};
