import React, { useState } from "react";
import { Route } from "react-router-dom";
import {
  ItemsCard,
  ItemsLoadingDialog,
  ItemsDeleteDialog,
  ItemDeleteDialog,
  ItemsFetchDialog,
  ItemsUpdateStatusDialog,
  ItemsUIProvider,
} from ".";
import { ItemCloneDialog } from "./ItemCloneDialog";

export const ItemsList = ({
  history,
  match,
  actions,
  uiHelpers,
  columns,
  entityName = null,
  customFetchAction = null,
  showAddButton = true,
  showCloneButton = true,
  actionsColumnFormatter = null,
  showActions = true,
  showViewButton = false,
  showDeleteButton=true,
  viewContentProfile= false,
  ShowPlusButton=false,
  ...props
}) => {
  const [slug] = useState(match.url);

  const UIEvents = {
    newItemButtonClick: () => {
      history.push(`${match.url}/new`);
    },
    newBulkImportItemButtonClick: () => {
      history.push(`${match.url}/bulk-update`);
    },
    openCloneItemDialog: (data) => {
      history.push({
        pathname: `${match.url}/${data.id}/clone`,
        data,
      });
    },
    openEditItemPage: (id) => {
      history.push(`${match.url}/${id}/edit`);
    },
    openAddSubItemPage: (id) => {
      history.push(`${match.url}/${id}/add-sub-data`);
    },
    openDeleteItemDialog: (id) => {
      history.push(`${match.url}/${id}/delete`);
    },
    openDeleteItemsDialog: () => {
      history.push(`${match.url}/delete`);
    },
    openFetchItemsDialog: () => {
      history.push(`${match.url}/fetch`);
    },
    openUpdateItemsStatusDialog: () => {
      history.push(`${match.url}/updateStatus`);
    },
    openRoleAssignmentPage: (id) => {
      history.push(`${match.url}/${id}/assignment`);
    },
    openViewUserProfile: () => {
      history.push(`/user-profile/person`);
    },
    openContentPage: (id) => {
      history.push(`${match.url}/${id}/view`) 
    }
  };

  return (
    <ItemsUIProvider
      initialFilter={{
        filter: {},
        sortOrder: "asc", // asc||desc
        sortField: "",
        pageNumber: 1,
        pageSize: 10,
      }}
      UIEvents={UIEvents}
    >
      <ItemsLoadingDialog name={uiHelpers.StateName} />
      <Route path={`${match.url}/:id/clone`}>
        {({ history, match }) => (
          <ItemCloneDialog
            uiHelpers={uiHelpers}
            actions={actions}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(slug);
            }}
          />
        )}
      </Route>
      <Route path={`${match.url}/delete`}>
        {({ history, match }) => (
          <ItemsDeleteDialog
            uiHelpers={uiHelpers}
            actions={actions}
            show={match != null}
            onHide={() => {
              history.push(slug);
            }}
          />
        )}
      </Route>
      <Route path={`${match.url}/:id/delete`}>
        {({ history, match }) => (
          <ItemDeleteDialog
            uiHelpers={uiHelpers}
            actions={actions}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(slug);
            }}
          />
        )}
      </Route>
     
      <Route path={`${match.url}/fetch`}>
        {({ history, match }) => (
          <ItemsFetchDialog
            uiHelpers={uiHelpers}
            renderItems={(item, index) => (
              <div className="list-timeline-item mb-3" key={item.id}>
                <span className="list-timeline-text">
                  <span className="label label-lg label-inline">
                    {index + 1}
                  </span>
                  <span className="ml-5">{item.textField}</span>
                </span>
              </div>
            )}
            show={match != null}
            onHide={() => {
              history.push(slug);
            }}
          />
        )}
      </Route>
      <Route path={`${match.url}/updateStatus`}>
        {({ history, match }) => (
          <ItemsUpdateStatusDialog
            actions={actions}
            uiHelpers={uiHelpers}
            renderItems={(item, index) => (
              <div className="list-timeline-item mb-3" key={item.id}>
                <span className="list-timeline-text">
                  <span className="label label-lg label-inline">
                    {index + 1}
                  </span>
                  <span className="ml-5">{item.textField}</span>
                </span>
              </div>
            )}
            statusData={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            show={match != null}
            onHide={() => {
              history.push(slug);
            }}
          />
        )}
      </Route>

      <ItemsCard
        uiHelpers={uiHelpers}
        actions={actions}
        columns={columns}
        entityName={entityName}
        customFetchAction={customFetchAction}
        showAddButton={showAddButton}
        showCloneButton={showCloneButton}
        showViewButton={showViewButton}
        showDeleteButton={showDeleteButton}
        actionsColumnFormatter={actionsColumnFormatter}
        showActions={showActions}
        ShowPlusButton={ShowPlusButton}
        {...props}
      />
    </ItemsUIProvider>
  );
};
