import React from "react";
// import OrganizationUser from "../modules/OrganizationUser/pages/OrganizationUser";

export function DashboardPage() {
  return (
    <>
    {/* <OrganizationUser /> */}
    </>
  )
}
