import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { assessmentTypesSlice } from "../app/modules/AssessmentTypes/_redux/slice";
import { assessmentsSlice } from "../app/modules/Assessments/_redux/slice";
import { userAssessmentRecordsSlice } from "../app/modules/UserAssessmentRecords/_redux/slice";
import { userScoresSlice } from "../app/modules/UserScores/_redux/slice";
import { appsSlice } from "../app/modules/Apps/_redux/slice";
import { subCategoriesSlice } from "../app/modules/SubCategories/_redux/slice";
import { activityTypesSlice } from "../app/modules/ActivityTypes/_redux/slice";
import { topicsSlice } from "../app/modules/Topics/_redux/slice";
import { commentsSlice } from "../app/modules/Comments/_redux/slice";
import { blockedCommentsSlice } from "../app/modules/BlockedComments/_redux/slice";
import { avatarAssetTypesSlice } from "../app/modules/AvatarAssetTypes/_redux/slice";
import { supportedColorsSlice } from "../app/modules/SupportedColors/_redux/slice";
import { activitiesSlice } from "../app/modules/Activities/_redux/slice";
import { userAvatarsSlice } from "../app/modules/UserAvatars/_redux/slice";
import { userActivityJourneySlice } from "../app/modules/UserActivityJourney/_redux/slice";
import { customActivityPathsSlice } from "../app/modules/CustomActivityPaths/_redux/slice";
import { userCustomActivityPathsSlice } from "../app/modules/UserCustomActivityPaths/_redux/slice";
import { achivementsSlice } from "../app/modules/Achivements/_redux/slice";
import { userAchivementsSlice } from "../app/modules/UserAchivements/_redux/slice";
import { categoriesSlice } from "../app/modules/Categories/_redux/slice";
import { usersSlice } from "../app/modules/Users/_redux/slice";
import { mastersSlice } from "./Masters/slice";
import { lifeTrackersSlice } from "../app/modules/LifeTrackers/_redux/slice";
import { peopleSlice } from "../app/modules/People/_redux/slice";
import { userHappinessesSlice } from "../app/modules/UserHappinesses/_redux/slice";
import { userMoodTrackersSlice } from "../app/modules/UserMoodTrackers/_redux/slice";
import { userReferralCodesSlice } from "../app/modules/UserReferralCodes/_redux/slice";
import { userStressesSlice } from "../app/modules/UserStresses/_redux/slice";
import { userTimeSpentLogsSlice } from "../app/modules/UserTimeSpentLogs/_redux/slice";
import { authProvidersSlice } from "../app/modules/AuthProviders/_redux/slice";
import { userAuthProvidersSlice } from "../app/modules/UserAuthProviders/_redux/slice";
import { productsSlice } from "../app/modules/Products/_redux/slice";
import { productRatingsSlice } from "../app/modules/ProductRatings/_redux/slice";
import { notificationTypesSlice } from "../app/modules/NotificationTypes/_redux/slice";
import { notificationTemplateSlice } from "../app/modules/NotificationTemplate/_redux/slice";
import { cacheManagementSlice } from "../app/modules/CacheManagement/_redux/slice";
import { rolesSlice } from "../app/modules/Roles/_redux/slice";
import { modulesSlice } from "../app/modules/Modules/_redux/slice";
import { permissionTypesSlice } from "../app/modules/PermissionTypes/_redux/slice";
import { permissionLevelsSlice } from "../app/modules/PermissionLevels/_redux/slice";
import { permissionsSlice } from "../app/modules/Permissions/_redux/slice";
import { userRolesSlice } from "../app/modules/UserRoles/_redux/slice";
import { organizationsSlice } from "../app/modules/Organization/_redux/slice";
import { InfluencerUsersSlice } from "../app/modules/InfluencerList/_redux/slice";
import { OrganizationUsersSlice } from "../app/modules/OrganizationList/_redux/slice";
import { gamesSlice } from "../app/modules/Games/_redux/slice";
import { spiritualsSlice } from "../app/modules/Spirituals/_redux/slice";
import { spiritualVideosSlice } from "../app/modules/SpiritualsVideos/_redux/slice";
import { newsSlice } from "../app/modules/News/_redux/slice";
import { newsCategorySlice } from "../app/modules/NewsCategory/_redux/slice";
import { appFeedbacksSlice } from "../app/modules/AppFeedback/_redux/slice";
import { contentTypesSlice } from "../app/modules/ContentType/_redux/slice";
import { contentSlice } from "../app/modules/Content/_redux/slice";
import { countryStateCitySlice } from "./CountryStateCity/slice";
import { userProfileDetailSlice } from "../app/modules/Profile/_redux/slice";
import { organizationUserSlice } from "../app/modules/OrganizationUser/_redux/slice";
import { blogFeaturesSlice } from "../app/modules/BlogFeature/_redux/slice";
import { pageFeaturesSlice } from "../app/modules/PageFeature/_redux/slice";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  assessmentTypes: assessmentTypesSlice.reducer,
  assessments: assessmentsSlice.reducer,
  userAssessmentRecords: userAssessmentRecordsSlice.reducer,
  userScores: userScoresSlice.reducer,
  apps: appsSlice.reducer,
  subCategories: subCategoriesSlice.reducer,
  activityTypes: activityTypesSlice.reducer,
  topics: topicsSlice.reducer,
  comments: commentsSlice.reducer,
  blockedComments: blockedCommentsSlice.reducer,
  avatarAssetTypes: avatarAssetTypesSlice.reducer,
  supportedColors: supportedColorsSlice.reducer,
  activities: activitiesSlice.reducer,
  userAvatars: userAvatarsSlice.reducer,
  userActivityJourney: userActivityJourneySlice.reducer,
  customActivityPaths: customActivityPathsSlice.reducer,
  userCustomActivityPaths: userCustomActivityPathsSlice.reducer,
  achivements: achivementsSlice.reducer,
  userAchivements: userAchivementsSlice.reducer,
  categories: categoriesSlice.reducer,
  users: usersSlice.reducer,
  masters: mastersSlice.reducer,
  lifeTrackers: lifeTrackersSlice.reducer,
  people: peopleSlice.reducer,
  userHappinesses: userHappinessesSlice.reducer,
  userMoodTrackers: userMoodTrackersSlice.reducer,
  userReferralCodes: userReferralCodesSlice.reducer,
  userStresses: userStressesSlice.reducer,
  userTimeSpentLogs: userTimeSpentLogsSlice.reducer,
  authProviders: authProvidersSlice.reducer,
  userAuthProviders: userAuthProvidersSlice.reducer,
  products: productsSlice.reducer,
  productRatings: productRatingsSlice.reducer,
  notificationTypes: notificationTypesSlice.reducer,
  notificationTemplates: notificationTemplateSlice.reducer,
  cacheManagement: cacheManagementSlice.reducer,
  roles: rolesSlice.reducer,
  modules: modulesSlice.reducer,
  permissionTypes: permissionTypesSlice.reducer,
  permissionLevels: permissionLevelsSlice.reducer,
  permissions: permissionsSlice.reducer,
  userRoles: userRolesSlice.reducer,
  organizations: organizationsSlice.reducer,
  influencerUser: InfluencerUsersSlice.reducer,
  // organizationUser:OrganizationUsersSlice.reducer,
  games: gamesSlice.reducer,
  spirituals: spiritualsSlice.reducer,
  spiritualVideos: spiritualVideosSlice.reducer,
  news: newsSlice.reducer,
  newsCategory: newsCategorySlice.reducer,
  appFeedbacks: appFeedbacksSlice.reducer,
  contentTypes: contentTypesSlice.reducer,
  content: contentSlice.reducer,
  countryStateCity: countryStateCitySlice.reducer,
  userProfileDetail: userProfileDetailSlice.reducer,
  organizationUser: organizationUserSlice.reducer,
  blogFeatures: blogFeaturesSlice.reducer,
  pageFeatures: pageFeaturesSlice.reducer,
});

export const callTypes = {
  list: "list",
  action: "action",
};

export const handleError = (
  dispatch,
  actions,
  callType,
  error,
  defaultMessage = "Something went wrong"
) => {
  const errorMessage = error ? error.message : defaultMessage;

  dispatch(
    actions.catchError({
      error: errorMessage,
      callType,
    })
  );

  setTimeout(() => {
    dispatch(actions.clearError());
  }, 10000);

  return Promise.reject(errorMessage);
};

export function* rootSaga() {
  yield all([auth.saga()]);
}
