import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { ItemsGrouping, ItemsFilter, ItemsTable, useUIContext } from ".";
import { userRole } from "../constant";

export const ItemsCard = ({
  uiHelpers,
  columns,
  showAddButton,
  showBulkImportButton,
  ...props
}) => {
  const { permissions, userRoles } = useSelector(
    (state) => ({
      permissions: state.auth.permissions,
      userRoles: state.auth.userRoles,
    }),
    shallowEqual
  );

  const UIContext = useUIContext();
  const UIProps = useMemo(() => {
    return {
      ids: UIContext.ids,
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
      newItemButtonClick: UIContext.newItemButtonClick,
      newBulkImportItemButtonClick: UIContext.newBulkImportItemButtonClick,
      openCloneItemDialog: UIContext.openCloneItemDialog,
      openDeleteItemsDialog: UIContext.openDeleteItemsDialog,
      openEditItemPage: UIContext.openEditItemPage,
      openAddSubItemPage: UIContext.openAddSubItemPage,
      openUpdateItemsStatusDialog: UIContext.openUpdateItemsStatusDialog,
      openFetchItemsDialog: UIContext.openFetchItemsDialog,
      openRoleAssignmentPage: UIContext.openRoleAssignmentPage,
      openViewUserProfile: UIContext.openViewUserProfile,
      openContentPage: UIContext.openContentPage,
    };
  }, [UIContext]);

  const hasPermission = (permissionKey) =>
    userRoles.includes(userRole.superAdmin) ||
    userRoles.includes(userRole.contentManager) ||
    userRoles.includes(userRole.contentAdmin) ||
    (permissions && permissions.includes(permissionKey));

  return (
    <Card>
      {/*  */}
      <CardHeader title={uiHelpers.PageName}>
        <CardHeaderToolbar>
          {showAddButton && hasPermission(`${uiHelpers.PageNameKey}_ADD`) && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={UIProps.newItemButtonClick}
            >
              New {uiHelpers.PageName}
            </button>
          )}
          {showBulkImportButton &&
            (userRoles.includes(userRole.superAdmin) ||
              (permissions &&
                permissions.includes(`${uiHelpers.PageNameKey}_ADD`))) && (
              <button
                type="button"
                className="btn btn-primary ml-4"
                onClick={UIProps.newBulkImportItemButtonClick}
              >
                Add Bulk {uiHelpers.PageName}
              </button>
            )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ItemsFilter columns={columns} />
        {UIProps.ids.length > 0 && (
          <>
            <ItemsGrouping />
          </>
        )}
        <ItemsTable uiHelpers={uiHelpers} columns={columns} {...props} />
      </CardBody>
    </Card>
  );
};
