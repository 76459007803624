import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  error: null,
  countries: undefined,
  states: null,
  cities: null,
};
export const callTypes = {
    list: "list",
    action: "action",
};

export const countryStateCitySlice = createSlice({
  name: "countryStateCity",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {state.error = `${action.type}: ${action.payload.error}`;
        state.listLoading = false;
      },
      startCall: (state, action) => {
        state.error = null;
        state.listLoading = true;
      },
    fetchCountries: (state, action) => {
      const { countries } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.countries = countries;
    },
    fetchStates: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.states = action.payload.states
    },
    fetchCity: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { cities } = action.payload;
      state.cities = cities;
    },

  },
});
