import React, { useRef, useEffect, useState } from "react";
import { connect, useDispatch, shallowEqual, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import { getUserByToken, getRolesAndPermissions } from "./authCrud";
import { useHistory } from "react-router-dom";
import { userRole } from "../../../../_component/constant";
// If you installed 'jsonwebtoken'
import jwt from "jsonwebtoken";

function AuthInit(props) {
  const history = useHistory();
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // We should request user by accessToken before rendering the application
  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authToken"));

    const requestUser = async () => {
      const roleAndPermission = await getRolesAndPermissions();

      const { userRoles, permissions } = roleAndPermission.data.data;
      console.log("after login =>", userRoles, permissions);
      try {
        if (!didRequest.current) {
          dispatch(props.requestUserPermissions());
          if (
            userRoles.includes(userRole.superAdmin) ||
            userRoles.includes(userRole.contentManager) ||
            userRoles.includes(userRole.contentAdmin) ||
            userRoles.includes(userRole.organizationAdmin)
          ) {
            const { data: user } = await getUserByToken();
            console.log("userData console =>", user);

            dispatch(props.fulfillUser(user));
            dispatch(props.setRolesAndPermissions(userRoles, permissions));
          } else {
            // dispatch(props.setAuthToken(undefined));
            dispatch(props.fulfillUser(undefined));
            localStorage.removeItem("authToken");
            history.push("/auth/login");
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
