/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { UIActions } from "../../../../../_component/constant";
import { userRole } from "../../../../../_component/constant";
// import { Icon } from "@material-ui/core";
// import SVG from "react-inlinesvg";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();

  const { permissions, userRoles } = useSelector(({ auth }) => ({
    permissions: auth.permissions,
    userRoles: auth.userRoles,
  }));

  console.log("userRoles===>", userRoles);
  console.log("permissions===>", permissions);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const menuItems = [
    {
      title: "Apps",
      key: "APPS",
      url: "/apps",
      icon: "app.png",
    },
    {
      title: "App Feedback",
      key: "APP_FEEDBACKS",
      url: "/app-feedbacks",
      icon: "app-feedback.png",
    },
    {
      title: "Games",
      key: "GAMES",
      url: "/games",
      icon: "games.png",
    },
    {
      title: "Spiritual People",
      key: "SPIRITUAL_PEOPLE",
      url: "/spirituals-people",
      icon: "spiritual-people.png",
    },
    {
      title: "Spiritual Videos",
      key: "SPIRITUAL_VIDEOS",
      url: "/spirituals-videos",
      icon: "spiritual-video.png",
    },
    {
      title: "News",
      key: "NEWS",
      url: "/news",
      icon: "news.png",
    },
    {
      title: "News Category",
      key: "NEWS_CATEGORIES",
      url: "/news-category",
      icon: "news-category.png",
    },

    {
      title: "Organization",
      key: "ORGANIZATIONS",
      url: "/organizations",
      icon: "organization.png",
    },
    {
      title: "Organization User",
      key: "ORGANIZATION_USER",
      url: "/organization-user",
      icon: "dashboard.png",
    },
    {
      title: "Cache Management",
      key: "CACHE_MANAGEMENT",
      url: "/cache-management/new",
      icon: "cache-management.png",
    },
    {
      title: "Category",
      key: "CATEGORIES",
      url: "/categories",
      icon: "category.png",
    },
    {
      title: "Sub Category",
      key: "SUB_CATEGORIES",
      url: "/sub-categories",
      icon: "sub-category.png",
    },
    {
      title: "Notification Type",
      key: "NOTIFICATION_TYPES",
      url: "/notification-types",
      icon: "notification-type.png",
    },
    {
      title: "Notification Template",
      key: "NOTIFICATION_TEMPLATES",
      url: "/notification-templates",
      icon: "notification-template.png",
    },
    {
      title: "User Auth Providers",
      key: "USER_AUTH_PROVIDERS",
      url: "/user-auth-providers",
      icon: "user-auth-provider.png",
    },

    // { title: "Topic", key: "TOPIC", url: "/topics", icon: "dashboar.pngd" },
    // { title: "Comment", key: "COMMENT", url: "/comments", icon: "dashboar.pngd" },
    // {
    //   title: "Blocked Comment",
    //   key: "BLOCKED_COMMENTS",
    //   url: "/blocked-comments",
    //   icon: "dashboard.png",
    // },
    // {
    //   title: "Avatar Asset Type",
    //   key: "AVATAR_ASSET_TYPES",
    //   url: "/avatar-asset-types",
    //   icon: "dashboard.png",
    // },
    // {
    //   title: "Supported Colors",
    //   key: "SUPPORTED_COLORS",
    //   url: "/supported-colors",
    //   icon: "dashboard.png",
    // },

    // {
    //   title: "User Avatar",
    //   key: "USER_AVATARS",
    //   url: "/user-avatars",
    //   icon: "dashboard.svg",
    // },
    // {
    //   title: "User Activity Journey Data",
    //   key: "USER_ACTIVITY_JOURNEIES",
    //   url: "/user-activity-journey",
    //   icon: "dashboard.svg",
    // },
    // {
    //   title: "Custom Activity Path",
    //   key: "CUSTOM_ACTIVITY_PATHS",
    //   url: "/custom-activity-paths",
    //   icon: "dashboard.svg",
    // },
    // {
    //   title: "User Custom Activity Path",
    //   key: "USER_CUSTOM_ACTIVITY_PATHS",
    //   url: "/user-custom-activity-paths",
    //   icon: "dashboard.svg",
    // },
    // {
    //   title: "Achivement",
    //   key: "ACHIVEMENT",
    //   url: "/achivements",
    //   icon: "dashboard.svg",
    // },
    // {
    //   title: "User Achivement",
    //   key: "USER_ACHIVEMENTS",
    //   url: "/user-achivements",
    //   icon: "dashboard.svg",
    // },

    // { title: "User", key: "USER", url: "/users", icon: "dashboard" },
  ];

  const menuItems1 = [
    {
      title: "Dashboard",
      key: "DASHBOARD",
      url: "/dashboard",
      icon: "/dashboard.png",
    },
  ];

  const menuItems2 = [
    {
      title: "Assessment",
      key: "ASSESSMENTS",
      url: "/assessments",
      icon: "assessment.png",
    },
    {
      title: "Assessment Type",
      key: "ASSESSMENT_TYPES",
      url: "/assessment-types",
      icon: "assessment-type.png",
    },
  ];

  const menuItems3 = [
    {
      title: "Activity Type",
      key: "ACTIVITY_TYPES",
      url: "/activity-types",
      icon: "activity-type.png",
    },
    {
      title: "Activity",
      key: "ACTIVITIES",
      url: "/activities",
      icon: "activities.png",
    },
    {
      title: "Content Type",
      key: "CONTENT_TYPES",
      url: "/content-types",
      icon: "content-type.png",
    },
    {
      title: "Content",
      key: "CONTENT",
      url: "/content",
      icon: "content.png",
    },
  ];

  const menuItems4 = [
    { title: "User", key: "USERS", url: "/users", icon: "user.png" },
    {
      title: "Organization List",
      key: "ORGANIZATION",
      url: "/organization-list",
      icon: "user.png",
    },
    {
      title: "Influencer List",
      key: "INFLUENCER",
      url: "/influencer-list",
      icon: "user.png",
    },

    // {
    //   title: "Auth Providers",
    //   key: "AUTH_PROVIDERS",
    //   url: "/auth-providers",
    //   icon: "tabs",
    // },

    // {
    //   title: "User Assessment Record",
    //   key: "USER_ASSESSMENT_RECORD",
    //   url: "/user-assessment-records",
    //   icon: "tabs",
    // },

    // {
    //   title: "User Score",
    //   key: "USER_SCORES",
    //   url: "/user-scores",
    //   icon: "feed",
    // },
    // {
    //   title: "People",
    //   key: "PEOPLE",
    //   url: "/people",
    //   icon: "feed",
    // },
    // {
    //   title: "User Happiness",
    //   key: "USER_HAPPINESSES",
    //   url: "/user-happinesses",
    //   icon: "feed",
    // },
    // {
    //   title: "User Mood Tracker",
    //   key: "USER_MOOD_TRACKERS",
    //   url: "/user-mood-trackers",
    //   icon: "feed",
    // },
    // {
    //   title: "User Referral Codes",
    //   key: "USER_REFERRAL_CODES",
    //   url: "/user-referral-codes",
    //   icon: "feed",
    // },
    // {
    //   title: "User Time Spent Logs",
    //   key: "USER_TIME_SPENT_LOGS",
    //   url: "/user-time-spent-logs",
    //   icon: "feed",
    // },
  ];

  const menuItems5 = [
    {
      title: "Product",
      key: "PRODUCTS",
      url: "/products",
      icon: "product.png",
    },
    {
      title: "Product Rating",
      key: "PRODUCT_RATINGS",
      url: "/product-ratings",
      icon: "product-rating.png",
    },
  ];

  const menuItems6 = [
    {
      title: "Roles",
      key: "ROLES",
      url: "/roles",
      icon: "role.png",
    },
    { title: "Modules", key: "MODULES", url: "/modules", icon: "Modules.png" },
    {
      title: "Permission Types",
      key: "PERMISSION_TYPES",
      url: "/permission-types",
      icon: "permission-type.png",
    },
    {
      title: "Permission Levels",
      key: "PERMISSION_LEVELS",
      url: "/permission-levels",
      icon: "permission-level.png",
    },
    {
      title: "Role Permissions",
      key: "ROLE_PERMISSIONS",
      url: "/role-permissions",
      icon: "role-permission.png",
    },
    {
      title: "User Roles",
      key: "USER_ROLES",
      url: "/user-roles",
      icon: "user-role.png",
    },
  ];

  const menuItems7 = [
    {
      title: "Blogs",
      key: "BLOGS",
      url: "/blogs",
      icon: "list.png",
    },
    {
      title: "Pages",
      key: "Page",
      url: "/page",
      icon: "list.png",
    },
  ];

  const menuItems8 = [
    {
      title: "Achivement",
      key: "ACHIVEMENT",
      url: "/achivement",
      icon: "content.png",
    },
    {
      title: "Badge",
      key: "BADGE",
      url: "/badge",
      icon: "content.png",
    },
  ];

  const superAdmin = userRoles && userRoles.includes(userRole.superAdmin);

  const [dashboardDropdownVisible, setDashboardDropdownVisible] = useState(
    true
  );

  const [dashboardDropdownVisible1, setDashboardDropdownVisible1] = useState(
    true
  );

  const [dashboardDropdownVisible2, setDashboardDropdownVisible2] = useState(
    true
  );

  const [dashboardDropdownVisible3, setDashboardDropdownVisible3] = useState(
    true
  );

  const [dashboardDropdownVisible4, setDashboardDropdownVisible4] = useState(
    true
  );

  // useEffect(() => {
  //   const hasPermissions = permissions && permissions.length > 0;
  //   const isSuperAdmin = userRoles.includes(userRole.superAdmin);
  //   setDashboardDropdownVisible(hasPermissions || isSuperAdmin);

  //   // Add similar checks and set state for other dropdowns

  // }, [permissions, userRoles]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className="menu-section card bg-primary pb-2">
          <h4 className="menu-text pt-4 text-white">JoyScore</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle px-2" to="/dashboard">
            <span className="svg-icon menu-icon p-1">
              <img
                className="w-100"
                alt="icon"
                src={toAbsoluteUrl(
                  `/media/png/icons/aside-menu-list-heading-icons/dashboard.png`
                )}
              />
            </span>
            <span className="menu-text">Dashboard</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="">
              {menuItems1.map((menuItem) => {
                const key = `${menuItem.key}_ACCESS`; 
                return (
                  (userRoles.includes(userRole.superAdmin) ||
                    // permissions.includes(UIActions[key])) && (
                    permissions&&permissions.includes(key)) && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        menuItem.url,
                        false
                      )}`}
                      aria-haspopup="true"
                      key={key}
                    >
                      <NavLink className="menu-link" to="/organization-user">
                        <span className="svg-icon menu-icon p-2">
                          <img
                            className="w-100"
                            alt="icon"
                            src={toAbsoluteUrl(
                              `/media/png/icons/aside-menu-list-heading-icons/${menuItem.icon}`
                            )}
                          />
                        </span>
                        <span className="menu-text">Dashboard</span>
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </li> */}
        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-2">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/master.png`
                    )}
                  />
                </span>
                <span className="menu-text">Masters</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="pl-5">
                  {menuItems.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;
                    //const key = `${menuItem.key}_READ`;
                    return (
                      userRoles.includes(userRole.superAdmin) &&
                      permissions &&
                      permissions.length > 0 &&
                      permissions &&
                      permissions.includes(key) && (
                        // permissions &&permissions.length>0&& permissions&&permissions.includes(key)) && (

                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon  menu-icon p-3">
                              <img
                                className="w-100"
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )}

        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-2">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/role&permission.png`
                    )}
                  />
                </span>
                <span className="menu-text">Roles and Permissions</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="">
                  {menuItems6.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;

                    return (
                      (userRoles.includes(userRole.superAdmin) ||
                        // permissions.includes(UIActions[key])) && (
                        (permissions &&
                          permissions.length > 0 &&
                          permissions &&
                          permissions.includes(key))) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon menu-icon p-3">
                              <img
                                className="w-100"
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )}

        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-2">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/assesment.png`
                    )}
                  />
                </span>
                <span className="menu-text">Assessment</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="">
                  {menuItems2.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;

                    return (
                      (userRoles.includes(userRole.superAdmin) ||
                        // permissions.includes(UIActions[key])) && (
                        (permissions && permissions.includes(key))) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon menu-icon p-3">
                              <img
                                className="w-100"
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )}

        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-2">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/activity.png`
                    )}
                  />
                </span>
                <span className="menu-text">Activity</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="">
                  {menuItems3.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;

                    return (
                      (userRoles.includes(userRole.superAdmin) ||
                        // permissions.includes(UIActions[key])) && (
                        (permissions && permissions.includes(key))) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon menu-icon p-3">
                              <img
                                className="w-100"
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )}
        {/* 
        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-2">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/achievement.png`
                    )}
                  />
                </span>
                <span className="menu-text">Achivement</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="">
                  {menuItems8.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;

                    return (
                      (userRoles.includes(userRole.superAdmin) ||
                        // permissions.includes(UIActions[key])) && (
                        (permissions && permissions.includes(key))) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon menu-icon p-3">
                              <img
                                className="w-100"
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )} */}

        {/* {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-2">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/achievement.png`
                    )}
                  />
                </span>
                <span className="menu-text">Achivement</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="">
                  {menuItems8.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;

                    return (
                      (userRoles.includes(userRole.superAdmin) ||
                        // permissions.includes(UIActions[key])) && (
                        (permissions && permissions.includes(key))) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon menu-icon p-3">
                              <img
                                className="w-100"
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )} */}

        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-2">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/achievement.png`
                    )}
                  />
                </span>
                <span className="menu-text">Achivement</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="">
                  {menuItems8.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;

                    return (
                      (userRoles.includes(userRole.superAdmin) ||
                        // permissions.includes(UIActions[key])) && (
                        (permissions && permissions.includes(key))) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon menu-icon p-3">
                              <img
                                className="w-100"
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )}

        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-1">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/user.png`
                    )}
                  />
                </span>
                <span className="menu-text">User</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="">
                  {menuItems4.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;
                    return (
                      (userRoles.includes(userRole.superAdmin) ||
                        // permissions.includes(UIActions[key])) && (
                        (permissions && permissions.includes(key))) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon menu-icon p-2">
                              <img
                                className="w-100"
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list-heading-icons/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )}

        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle px-2"
                to="/google-material"
              >
                <span className="svg-icon menu-icon p-2">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/product.png`
                    )}
                  />
                </span>
                <span className="menu-text">Product</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="">
                  {menuItems5.map((menuItem) => {
                    const key = `${menuItem.key}_READ`;

                    return (
                      (userRoles.includes(userRole.superAdmin) ||
                        // permissions.includes(UIActions[key])) && (
                        (permissions && permissions.includes(key))) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            menuItem.url,
                            false
                          )}`}
                          aria-haspopup="true"
                          key={key}
                        >
                          <NavLink className="menu-link" to={menuItem.url}>
                            <span className="svg-icon menu-icon p-4">
                              <img
                                alt="icon"
                                src={toAbsoluteUrl(
                                  `/media/png/icons/aside-menu-list/${menuItem.icon}`
                                )}
                              />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                          </NavLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </li>
          )}

        {(userRoles.includes(userRole.superAdmin) ||
          userRoles.includes(userRole.contentManager) ||
          (userRoles.includes(userRole.contentAdmin) &&
            permissions &&
            permissions.length > 0)) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle px-2"
              to="/google-material"
            >
              <span className="svg-icon menu-icon p-2">
                <img
                  className="w-100"
                  alt="icon"
                  src={toAbsoluteUrl(
                    `/media/png/icons/aside-menu-list-heading-icons/blog.png`
                  )}
                />
              </span>
              <span className="menu-text">Blog</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul>
                {menuItems7.map((menuItem) => {
                  const key = `${menuItem.key}_READ`;

                  return (
                    (userRoles.includes(userRole.superAdmin) ||
                      userRoles.includes(userRole.contentManager) ||
                      userRoles.includes(userRole.contentAdmin)) && (
                      //  && permissions &&
                      //   permissions.includes(key)
                      <li
                        className={`menu-item ${getMenuItemActive(
                          menuItem.url,
                          false
                        )}`}
                        aria-haspopup="true"
                        key={key}
                      >
                        <NavLink className="menu-link" to={menuItem.url}>
                          <span className="svg-icon menu-icon p-4">
                            <img
                              alt="icon"
                              src={toAbsoluteUrl(
                                `/media/png/icons/aside-menu-list/${menuItem.icon}`
                              )}
                            />
                          </span>
                          <span className="menu-text">{menuItem.title}</span>
                        </NavLink>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          </li>
        )}

        {userRoles.includes(userRole.superAdmin) &&
          permissions &&
          permissions.length > 0 && (
            <li
              className="menu-item menu -item-submenu"
              // className={`menu-item menu-item-submenu ${getMenuItemActive(
              //   "/google-material",
              //   true
              // )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle px-2" to="/profile">
                <span className="svg-icon menu-icon p-1">
                  <img
                    className="w-100"
                    alt="icon"
                    src={toAbsoluteUrl(
                      `/media/png/icons/aside-menu-list-heading-icons/profile12.png`
                    )}
                  />
                </span>
                <span className="menu-text">Profile</span>
                <i className="menu-arrow" />
              </NavLink>
              {/* <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="">
              {menuItems4.map((menuItem) => {
                  const key = `${menuItem.key}_ACCESS`;
                  return (
                    (userRoles.includes(userRole.superAdmin) ||
                      // permissions.includes(UIActions[key])) && (
                      permissions &&permissions.length>0&& permissions&&permissions.includes(key)) && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        menuItem.url,
                        false
                      )}`}
                      aria-haspopup="true"
                      key={key}
                    >
                      <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon p-2">
                          <img
                            className="w-100"
                            alt="icon"
                            // src={toAbsoluteUrl(
                            //   `/media/png/icons/aside-menu-list-heading-icons/${menuItem.icon}`
                            // )}
                          />
                        </span>
                        <span className="menu-text">Dashboard</span>
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div> */}
            </li>
          )}

        {/*end::1 Level*/}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
