import React from "react";
import { useSelector } from "react-redux";
import * as actions from "../_redux/actions";
import * as uiHelpers from "./ItemsUIHelpers";
import { ItemsList } from "../../../../_component/list/ItemsList";
import { sortCaret } from "../../../../_metronic/_helpers";

export default (props) => {
  const { userRoles } = useSelector(({ auth }) => ({
    userRoles: auth.userRoles ? auth.userRoles : null,
  }));

  return (
    <ItemsList
      {...props}
      actions={actions}
      uiHelpers={uiHelpers}
      userRoles={userRoles}
      columns={[
        {
          dataField: "date",
          text: "Date",
          sort: true,
          sortCaret,
        },
        {
          dataField: "userEmotionType",
          text: "User Emotion Type",
          sort: true,
          sortCaret,
        },
        {
          dataField: "userEmotionConfidence",
          text: "User Emotion Confidence",
          sort: true,
          sortCaret,
        },
        {
          dataField: "text",
          text: "Text",
          sort: true,
          sortCaret,
        },
      ]}
    />
  );
};
