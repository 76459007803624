import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import jwt from "jsonwebtoken";
import {
  getUserByToken,
  getOrganizations,
  getRolesAndPermissions,
} from "./authCrud";
import { userRole } from "../../../../_component/constant";

export const actionTypes = {
  Login: "[Login] Action",
  SetAuthToken: "[SetAuthToken] Action",
  SetRolesAndPermissions: "[SetRolesAndPermissions] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  OrganizationsLoaded: "[Load Organizations] API",
  OrganizationRequested: "[Request Organizations] Action",
  UserPermissionsRequested: "[Request User Permissions] Action",
  UserPermissionsLoaded: "[Load User Permissions] API",
};

const initialAuthState = {
  user: null,
  userRoles: [],
  organizations: [],
  permissions: null,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v726-demo1-auth",
    whitelist: [],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { accessToken, refreshToken, referralToken } = action.payload;
        localStorage.setItem(
          "authToken",
          JSON.stringify({ accessToken, refreshToken, referralToken })
        );

        const decodedToken = jwt.decode(accessToken);
        const { userRoles, permissions } = decodedToken;
        console.log(
          "at the time of login userRoles, permissions =>",
          userRoles,
          permissions
        );

        return {
          ...state,
          userRoles: userRoles,
          permissions: permissions,
        };
      }

      case actionTypes.SetRolesAndPermissions: {
        const { userRoles, userPermissions } = action.payload;
        return { ...state, userRoles: userRoles, permissions: userPermissions };
      }

      case actionTypes.SetAuthToken: {
        const { accessToken, refreshToken, referralToken } = action.payload;
        localStorage.removeItem("authToken");
        localStorage.setItem(
          "authToken",
          JSON.stringify({ accessToken, refreshToken, referralToken })
        );

        return state;
      }

      case actionTypes.OrganizationsLoaded: {
        const { organizations } = action.payload;
        return { ...state, organizations };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.removeItem("authToken");
        return { ...state, ...initialAuthState };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;

        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken, refreshToken, referralToken) => ({
    type: actionTypes.Login,
    payload: { accessToken, refreshToken, referralToken },
  }),
  setRolesAndPermissions: (userRoles, userPermissions) => ({
    type: actionTypes.SetRolesAndPermissions,
    payload: { userRoles, userPermissions },
  }),
  setRolesAndPermissions: (userRoles, userPermissions) => ({
    type: actionTypes.SetRolesAndPermissions,
    payload: { userRoles, userPermissions },
  }),
  setAuthToken: (accessToken, refreshToken, referralToken) => ({
    type: actionTypes.SetAuthToken,
    payload: { accessToken, refreshToken, referralToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),

  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),

  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  requestOrganizations: () => ({
    type: actionTypes.OrganizationRequested,
    payload: {},
  }),

  fulfillOrganizations: (organizations) => ({
    type: actionTypes.OrganizationsLoaded,
    payload: { organizations },
  }),
  requestUserPermissions: () => ({
    type: actionTypes.UserPermissionsRequested,
    payload: {},
  }),
  fulfillUserPermissions: (userRoles, permissions) => ({
    type: actionTypes.UserPermissionsLoaded,
    payload: { userRoles, permissions },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const authToken = JSON.parse(localStorage.getItem("authToken"));
    const roleAndPermission = yield getRolesAndPermissions();

    const { userRoles, permissions } = roleAndPermission.data.data;
    console.log("after login =>", userRoles, permissions);

    if (
      userRoles.includes(userRole.superAdmin) ||
      userRoles.includes(userRole.contentManager) ||
      userRoles.includes(userRole.contentAdmin) ||
      userRoles.includes(userRole.organizationAdmin)
    ) {
      const { data: user } = yield getUserByToken();
      console.log("getUserByToken console =>", user);

      // const { data: organization } = yield getOrganizations();
      // yield put(actions.fulfillOrganizations(organization));
      yield put(actions.setRolesAndPermissions(userRoles, permissions));
      yield put(actions.fulfillUser(user));
    } else {
      localStorage.removeItem("authToken");
      yield put(actions.fulfillUser(undefined));
    }
  });

  yield takeLatest(
    actionTypes.OrganizationRequested,
    function* organizationRequested() {
      // const { data: organization } = yield getOrganizations();
      // yield put(actions.fulfillOrganizations(organization));
    }
  );
}
