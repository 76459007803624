import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import List from "./List";
import Form from "./Form";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import UserProfilePage from "../../UserProfile/UserProfilePage";
import PersonPage from "../../People/pages/Form";

export default function UserPage({ match }) {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute path={`${match.url}/new`} component={Form} />
        <ContentRoute path={`${match.url}/:id/edit`} component={Form} />
        <ContentRoute path={`${match.url}/:id/view`} component={Form} />
        <ContentRoute path={`${match.url}/#`} component={UserProfilePage} />
        <ContentRoute
          path={`${match.url}/life-tracker`}
          component={UserProfilePage}
        />
        <ContentRoute
          path={`${match.url}/user-mood-tracker`}
          component={UserProfilePage}
        />
        <ContentRoute
          path={`${match.url}/user-time-spent`}
          component={UserProfilePage}
        />
        <ContentRoute
          path={`${match.url}/user-stress`}
          component={UserProfilePage}
        />
        <ContentRoute
          path={`${match.url}/user-happiness`}
          component={UserProfilePage}
        />
        <ContentRoute path={match.url} component={List} />
        <Redirect from={match.url} to="/errors" />
      </Switch>
    </Suspense>
  );
}
