export const Default = "Default";
export const Custom = "Custom";
export const userRole = {
  superAdmin: "Super Admin",
  contentManager: "Content Manager",
  organizationAdmin: "Organization Admin",
  contentAdmin: "Content Admin",
  influencer: "Influencer",
};
export const statusType = [
  { name: "Active", value: "Active" },
  { name: "Inactive", value: "Inactive" },
];

export const authorName = [
  { name: "Professor Joy", value: "Professor Joy" },
  { name: "Varsha Gupta", value: "Varsha Gupta" },
  { name: "Dr. P. Welty", value: "Dr. P. Welty" },
];

export const categoryName = [
  { value: "mind", name: "Mind" },
  { value: "body", name: "Body" },
  { value: "life", name: "Life" },
  { value: "other", name: "Other" },
];

export const subCategoryName = [
  { value: "actions", name: "Actions" },
  { value: "thoughs", name: "Thoughs" },
  { value: "self esteem", name: "Self Esteem" },
  { value: "fitness", name: "Fitness" },
  { value: "Nutrition", name: "Nutrition" },
  { value: "breathing", name: "Breathing" },
  { value: "stress", name: "Stress" },
  { value: "relationships", name: "Relationships" },
  { value: "wealth,belief&nature", name: "Wealth , Belief & Nature" },
  { value: "other", name: "Other" },
];

export const newsCategory = [
  { name: "Health", value: "Health" },
  { name: "Politics", value: "Politics" },
  { name: "Art", value: "Art" },
  { name: "Food", value: "Food" },
  { name: "Book", value: "Book" },
];
export const videoSourceType = [
  { name: "URL", value: "URL" },
  { name: "Upload", value: "Upload" },
];
// export const primaryType = [
//   { name: "True", value: "True" },
//   { name: "False", value: "False" },
// ];

export const UIActions = {
  ASSESSMENT_TYPES_Add: "ASSESSMENT_TYPES_Add ",
  ASSESSMENT_TYPES_Edit: "ASSESSMENT_TYPES_Edit ",
  ASSESSMENT_TYPES_Read: "ASSESSMENT_TYPES_Read ",
  ASSESSMENT_TYPES_View: "ASSESSMENT_TYPES_View ",
  DELETE_ASSESSMENT_TYPE: "ASSESSMENT_TYPES_Delete ",
  MANAGE_ASSESSMENT_TYPE: "ASSESSMENT_TYPES_Manage ",

  // CLONE_ASSESSMENT_TYPE: "ASSESSMENT_TYPES_Clone ",
  // IMPORT_ASSESSMENT_TYPE: "ASSESSMENT_TYPES_Import ",
  // EXPORT_ASSESSMENT_TYPE: "ASSESSMENT_TYPES_Export ",
  // PRINT_ASSESSMENT_TYPE: "ASSESSMENT_TYPES_Print ",
  // DOWNLOAD_ASSESSMENT_TYPE: "ASSESSMENT_TYPES_Download ",
  // MANAGE_ASSESSMENT_TYPE: "ASSESSMENT_TYPES_Manage ",
  ADD_ASSESSMENT: "Add Assessment",
  EDIT_ASSESSMENT: "Edit Assessment",
  LIST_ASSESSMENT: "List Assessment",
  VIEW_ASSESSMENT: "View Assessment",
  DELETE_ASSESSMENT: "Delete Assessment",
  CLONE_ASSESSMENT: "Clone Assessment",
  IMPORT_ASSESSMENT: "Import Assessment",
  EXPORT_ASSESSMENT: "Export Assessment",
  PRINT_ASSESSMENT: "Print Assessment",
  DOWNLOAD_ASSESSMENT: "Download Assessment",
  MANAGE_ASSESSMENT: "Manage Assessment",
  ADD_USER_ASSESSMENT_RECORD: "Add User Assessment Record",
  EDIT_USER_ASSESSMENT_RECORD: "Edit User Assessment Record",
  LIST_USER_ASSESSMENT_RECORD: "List User Assessment Record",
  VIEW_USER_ASSESSMENT_RECORD: "View User Assessment Record",
  DELETE_USER_ASSESSMENT_RECORD: "Delete User Assessment Record",
  CLONE_USER_ASSESSMENT_RECORD: "Clone User Assessment Record",
  IMPORT_USER_ASSESSMENT_RECORD: "Import User Assessment Record",
  EXPORT_USER_ASSESSMENT_RECORD: "Export User Assessment Record",
  PRINT_USER_ASSESSMENT_RECORD: "Print User Assessment Record",
  DOWNLOAD_USER_ASSESSMENT_RECORD: "Download User Assessment Record",
  MANAGE_USER_ASSESSMENT_RECORD: "Manage User Assessment Record",
  ADD_USER_SCORE: "Add User Score",
  EDIT_USER_SCORE: "Edit User Score",
  LIST_USER_SCORE: "List User Score",
  VIEW_USER_SCORE: "View User Score",
  DELETE_USER_SCORE: "Delete User Score",
  CLONE_USER_SCORE: "Clone User Score",
  IMPORT_USER_SCORE: "Import User Score",
  EXPORT_USER_SCORE: "Export User Score",
  PRINT_USER_SCORE: "Print User Score",
  DOWNLOAD_USER_SCORE: "Download User Score",
  MANAGE_USER_SCORE: "Manage User Score",
  ADD_APPS: "Add Apps",
  EDIT_APPS: "Edit Apps",
  LIST_APPS: "List Apps",
  VIEW_APPS: "View Apps",
  DELETE_APPS: "Delete Apps",
  CLONE_APPS: "Clone Apps",
  IMPORT_APPS: "Import Apps",
  EXPORT_APPS: "Export Apps",
  PRINT_APPS: "Print Apps",
  DOWNLOAD_APPS: "Download Apps",
  MANAGE_APPS: "Manage Apps",
  ADD_SUB_CATEGORY: "Add Sub Category",
  EDIT_SUB_CATEGORY: "Edit Sub Category",
  LIST_SUB_CATEGORY: "List Sub Category",
  VIEW_SUB_CATEGORY: "View Sub Category",
  DELETE_SUB_CATEGORY: "Delete Sub Category",
  CLONE_SUB_CATEGORY: "Clone Sub Category",
  IMPORT_SUB_CATEGORY: "Import Sub Category",
  EXPORT_SUB_CATEGORY: "Export Sub Category",
  PRINT_SUB_CATEGORY: "Print Sub Category",
  DOWNLOAD_SUB_CATEGORY: "Download Sub Category",
  MANAGE_SUB_CATEGORY: "Manage Sub Category",
  ADD_ACTIVITY_TYPE: "Add Activity Type",
  EDIT_ACTIVITY_TYPE: "Edit Activity Type",
  LIST_ACTIVITY_TYPE: "List Activity Type",
  VIEW_ACTIVITY_TYPE: "View Activity Type",
  DELETE_ACTIVITY_TYPE: "Delete Activity Type",
  CLONE_ACTIVITY_TYPE: "Clone Activity Type",
  IMPORT_ACTIVITY_TYPE: "Import Activity Type",
  EXPORT_ACTIVITY_TYPE: "Export Activity Type",
  PRINT_ACTIVITY_TYPE: "Print Activity Type",
  DOWNLOAD_ACTIVITY_TYPE: "Download Activity Type",
  MANAGE_ACTIVITY_TYPE: "Manage Activity Type",
  ADD_TOPIC: "Add Topic",
  EDIT_TOPIC: "Edit Topic",
  LIST_TOPIC: "List Topic",
  VIEW_TOPIC: "View Topic",
  DELETE_TOPIC: "Delete Topic",
  CLONE_TOPIC: "Clone Topic",
  IMPORT_TOPIC: "Import Topic",
  EXPORT_TOPIC: "Export Topic",
  PRINT_TOPIC: "Print Topic",
  DOWNLOAD_TOPIC: "Download Topic",
  MANAGE_TOPIC: "Manage Topic",
  ADD_COMMENT: "Add Comment",
  EDIT_COMMENT: "Edit Comment",
  LIST_COMMENT: "List Comment",
  VIEW_COMMENT: "View Comment",
  DELETE_COMMENT: "Delete Comment",
  CLONE_COMMENT: "Clone Comment",
  IMPORT_COMMENT: "Import Comment",
  EXPORT_COMMENT: "Export Comment",
  PRINT_COMMENT: "Print Comment",
  DOWNLOAD_COMMENT: "Download Comment",
  MANAGE_COMMENT: "Manage Comment",
  ADD_BLOCKED_COMMENT: "Add Blocked Comment",
  EDIT_BLOCKED_COMMENT: "Edit Blocked Comment",
  LIST_BLOCKED_COMMENT: "List Blocked Comment",
  VIEW_BLOCKED_COMMENT: "View Blocked Comment",
  DELETE_BLOCKED_COMMENT: "Delete Blocked Comment",
  CLONE_BLOCKED_COMMENT: "Clone Blocked Comment",
  IMPORT_BLOCKED_COMMENT: "Import Blocked Comment",
  EXPORT_BLOCKED_COMMENT: "Export Blocked Comment",
  PRINT_BLOCKED_COMMENT: "Print Blocked Comment",
  DOWNLOAD_BLOCKED_COMMENT: "Download Blocked Comment",
  MANAGE_BLOCKED_COMMENT: "Manage Blocked Comment",
  ADD_AVATAR_ASSET_TYPE: "Add Avatar Asset Type",
  EDIT_AVATAR_ASSET_TYPE: "Edit Avatar Asset Type",
  LIST_AVATAR_ASSET_TYPE: "List Avatar Asset Type",
  VIEW_AVATAR_ASSET_TYPE: "View Avatar Asset Type",
  DELETE_AVATAR_ASSET_TYPE: "Delete Avatar Asset Type",
  CLONE_AVATAR_ASSET_TYPE: "Clone Avatar Asset Type",
  IMPORT_AVATAR_ASSET_TYPE: "Import Avatar Asset Type",
  EXPORT_AVATAR_ASSET_TYPE: "Export Avatar Asset Type",
  PRINT_AVATAR_ASSET_TYPE: "Print Avatar Asset Type",
  DOWNLOAD_AVATAR_ASSET_TYPE: "Download Avatar Asset Type",
  MANAGE_AVATAR_ASSET_TYPE: "Manage Avatar Asset Type",
  ADD_SUPPORTED_COLORS: "Add Supported Colors",
  EDIT_SUPPORTED_COLORS: "Edit Supported Colors",
  LIST_SUPPORTED_COLORS: "List Supported Colors",
  VIEW_SUPPORTED_COLORS: "View Supported Colors",
  DELETE_SUPPORTED_COLORS: "Delete Supported Colors",
  CLONE_SUPPORTED_COLORS: "Clone Supported Colors",
  IMPORT_SUPPORTED_COLORS: "Import Supported Colors",
  EXPORT_SUPPORTED_COLORS: "Export Supported Colors",
  PRINT_SUPPORTED_COLORS: "Print Supported Colors",
  DOWNLOAD_SUPPORTED_COLORS: "Download Supported Colors",
  MANAGE_SUPPORTED_COLORS: "Manage Supported Colors",
  ADD_ACTIVITY: "Add Activity",
  EDIT_ACTIVITY: "Edit Activity",
  LIST_ACTIVITY: "List Activity",
  VIEW_ACTIVITY: "View Activity",
  DELETE_ACTIVITY: "Delete Activity",
  CLONE_ACTIVITY: "Clone Activity",
  IMPORT_ACTIVITY: "Import Activity",
  EXPORT_ACTIVITY: "Export Activity",
  PRINT_ACTIVITY: "Print Activity",
  DOWNLOAD_ACTIVITY: "Download Activity",
  MANAGE_ACTIVITY: "Manage Activity",
  ADD_USER_AVATAR: "Add User Avatar",
  EDIT_USER_AVATAR: "Edit User Avatar",
  LIST_USER_AVATAR: "List User Avatar",
  VIEW_USER_AVATAR: "View User Avatar",
  DELETE_USER_AVATAR: "Delete User Avatar",
  CLONE_USER_AVATAR: "Clone User Avatar",
  IMPORT_USER_AVATAR: "Import User Avatar",
  EXPORT_USER_AVATAR: "Export User Avatar",
  PRINT_USER_AVATAR: "Print User Avatar",
  DOWNLOAD_USER_AVATAR: "Download User Avatar",
  MANAGE_USER_AVATAR: "Manage User Avatar",
  ADD_USER_ACTIVITY_JOURNEY_DATA: "Add User Activity Journey Data",
  EDIT_USER_ACTIVITY_JOURNEY_DATA: "Edit User Activity Journey Data",
  LIST_USER_ACTIVITY_JOURNEY_DATA: "List User Activity Journey Data",
  VIEW_USER_ACTIVITY_JOURNEY_DATA: "View User Activity Journey Data",
  DELETE_USER_ACTIVITY_JOURNEY_DATA: "Delete User Activity Journey Data",
  CLONE_USER_ACTIVITY_JOURNEY_DATA: "Clone User Activity Journey Data",
  IMPORT_USER_ACTIVITY_JOURNEY_DATA: "Import User Activity Journey Data",
  EXPORT_USER_ACTIVITY_JOURNEY_DATA: "Export User Activity Journey Data",
  PRINT_USER_ACTIVITY_JOURNEY_DATA: "Print User Activity Journey Data",
  DOWNLOAD_USER_ACTIVITY_JOURNEY_DATA: "Download User Activity Journey Data",
  MANAGE_USER_ACTIVITY_JOURNEY_DATA: "Manage User Activity Journey Data",
  ADD_CUSTOM_ACTIVITY_PATH: "Add Custom Activity Path",
  EDIT_CUSTOM_ACTIVITY_PATH: "Edit Custom Activity Path",
  LIST_CUSTOM_ACTIVITY_PATH: "List Custom Activity Path",
  VIEW_CUSTOM_ACTIVITY_PATH: "View Custom Activity Path",
  DELETE_CUSTOM_ACTIVITY_PATH: "Delete Custom Activity Path",
  CLONE_CUSTOM_ACTIVITY_PATH: "Clone Custom Activity Path",
  IMPORT_CUSTOM_ACTIVITY_PATH: "Import Custom Activity Path",
  EXPORT_CUSTOM_ACTIVITY_PATH: "Export Custom Activity Path",
  PRINT_CUSTOM_ACTIVITY_PATH: "Print Custom Activity Path",
  DOWNLOAD_CUSTOM_ACTIVITY_PATH: "Download Custom Activity Path",
  MANAGE_CUSTOM_ACTIVITY_PATH: "Manage Custom Activity Path",
  ADD_USER_CUSTOM_ACTIVITY_PATH: "Add User Custom Activity Path",
  EDIT_USER_CUSTOM_ACTIVITY_PATH: "Edit User Custom Activity Path",
  LIST_USER_CUSTOM_ACTIVITY_PATH: "List User Custom Activity Path",
  VIEW_USER_CUSTOM_ACTIVITY_PATH: "View User Custom Activity Path",
  DELETE_USER_CUSTOM_ACTIVITY_PATH: "Delete User Custom Activity Path",
  CLONE_USER_CUSTOM_ACTIVITY_PATH: "Clone User Custom Activity Path",
  IMPORT_USER_CUSTOM_ACTIVITY_PATH: "Import User Custom Activity Path",
  EXPORT_USER_CUSTOM_ACTIVITY_PATH: "Export User Custom Activity Path",
  PRINT_USER_CUSTOM_ACTIVITY_PATH: "Print User Custom Activity Path",
  DOWNLOAD_USER_CUSTOM_ACTIVITY_PATH: "Download User Custom Activity Path",
  MANAGE_USER_CUSTOM_ACTIVITY_PATH: "Manage User Custom Activity Path",
  ADD_ACHIVEMENT: "Add Achivement",
  EDIT_ACHIVEMENT: "Edit Achivement",
  LIST_ACHIVEMENT: "List Achivement",
  VIEW_ACHIVEMENT: "View Achivement",
  DELETE_ACHIVEMENT: "Delete Achivement",
  CLONE_ACHIVEMENT: "Clone Achivement",
  IMPORT_ACHIVEMENT: "Import Achivement",
  EXPORT_ACHIVEMENT: "Export Achivement",
  PRINT_ACHIVEMENT: "Print Achivement",
  DOWNLOAD_ACHIVEMENT: "Download Achivement",
  MANAGE_ACHIVEMENT: "Manage Achivement",
  ADD_USER_ACHIVEMENT: "Add User Achivement",
  EDIT_USER_ACHIVEMENT: "Edit User Achivement",
  LIST_USER_ACHIVEMENT: "List User Achivement",
  VIEW_USER_ACHIVEMENT: "View User Achivement",
  DELETE_USER_ACHIVEMENT: "Delete User Achivement",
  CLONE_USER_ACHIVEMENT: "Clone User Achivement",
  IMPORT_USER_ACHIVEMENT: "Import User Achivement",
  EXPORT_USER_ACHIVEMENT: "Export User Achivement",
  PRINT_USER_ACHIVEMENT: "Print User Achivement",
  DOWNLOAD_USER_ACHIVEMENT: "Download User Achivement",
  MANAGE_USER_ACHIVEMENT: "Manage User Achivement",
  ADD_CATEGORY: "Add Category",
  EDIT_CATEGORY: "Edit Category",
  LIST_CATEGORY: "List Category",
  VIEW_CATEGORY: "View Category",
  DELETE_CATEGORY: "Delete Category",
  CLONE_CATEGORY: "Clone Category",
  IMPORT_CATEGORY: "Import Category",
  EXPORT_CATEGORY: "Export Category",
  PRINT_CATEGORY: "Print Category",
  DOWNLOAD_CATEGORY: "Download Category",
  MANAGE_CATEGORY: "Manage Category",
  ADD_USER: "Add User",
  EDIT_USER: "Edit User",
  LIST_USER: "List User",
  VIEW_USER: "View User",
  DELETE_USER: "Delete User",
  CLONE_USER: "Clone User",
  IMPORT_USER: "Import User",
  EXPORT_USER: "Export User",
  PRINT_USER: "Print User",
  DOWNLOAD_USER: "Download User",
  MANAGE_USER: "Manage User",
};
export const ALL_FILE_FORMATS = ".jpg,.jpeg,.png,.gif,.txt,.pdf,.doc,.docx";
export const IMAGE_FORMATS = ".jpg,.jpeg,.png,.gif,.txt,.pdf,.doc,.docx";
export const DOCUMENT_FORMATS = ".jpg,.jpeg,.png,.gif,.txt,.pdf,.doc,.docx";

export const MOBILE_NUMBER_REGEX = "^[6-9][0-9]{9}$";
