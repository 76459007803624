import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  apps: null,
  categories: null,
  subCategories: null,
  error: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const mastersSlice = createSlice({
  name: "masters",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.data = undefined;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // findSelectItems
    appsFetched: (state, action) => {
      const { searchItems } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.apps = searchItems;
    },

    categoriesFetched: (state, action) => {
      const { searchItems } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.categories = searchItems;
    },

    subCategoriesFetched: (state, action) => {
      const { searchItems } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.subCategories = searchItems;
    },
  },
});
