/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";

const SwitchProfileDropdownToggle = forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      className="btn btn-clean border-dark btn-sm btn-icon"
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
});

export function TopActions() {
  return (
    <Dropdown className="dropdown dropdown-inline mr-10" drop="down" alignRight>
      <Dropdown.Toggle
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
        variant="transparent"
        id="dropdown-toggle-top-user-profile"
        as={SwitchProfileDropdownToggle}
      >
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
        <ul className="navi navi-hover py-5">
          <li className="navi-item">
            <a href="/logout" className="navi-link">
              <span className="navi-icon">
                <i className="flaticon-logout"></i>
              </span>
              <span className="navi-text">Logout</span>
            </a>
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
