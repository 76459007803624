import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}sso-api/login`;
export const VALIDATE_TOKEN = `${process.env.REACT_APP_API_URL}sso-api/validate-token`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}sso-api/user/me`;
export const USER_PERMISSIONS_URL = `${process.env.REACT_APP_API_URL}user-authorization-api/user-permissions`;

export async function login(email, password) {
  const response = await axios.post(LOGIN_URL, { email, password });

  return response.data;
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getRolesAndPermissions() {
  return axios.get(`${process.env.REACT_APP_API_URL}sso-api/get-user-permissions`);
// return { data: { userRoles: ["Super Admin"], permissions: {} } };
}

export async function validateReferralToken(referralToken) {
  const tokens = await axios.post(VALIDATE_TOKEN, { referralToken });

  return tokens;
}

export function getOrganizations() {
  return axios.get(`${process.env.REACT_APP_API_URL}sso-api/organizations`);
}
