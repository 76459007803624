export const PageName = "User Stress";
export const PageNameKey = "USER_STRESS";
export const StateName = "userStresses";
export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const initialFilter = {
  filter: {},
  sortOrder: "asc", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 10,
};
