import React, { useEffect, useState } from "react";
import { SignedUploader, UploadField } from "@navjobs/upload";
import { Button, ProgressBar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { FieldFeedbackLabel } from "../../_metronic/_partials/controls/forms/FieldFeedbackLabel";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { ALL_FILE_FORMATS } from "../constant";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const fileUploadManagerApiUrl = `${process.env.REACT_APP_API_URL}file-upload-manager-api/`;

export function File({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  index,
  indexValue = false,
  fileNameAfterIndex = "",
  indexValues = 0,
  withFeedbackLabel = false,
  customFeedbackLabel,
  disabled = false,
  id,
  ifArray = [],
  onUpload = null,
  multiple = false,
  accept = ALL_FILE_FORMATS,
  showImage = true,
  ...props
}) {
  const location = useLocation();
  const [uploadFieldKey, setUploadFieldKey] = useState(
    `${label}-${
      values && values[field.name] && multiple
        ? values && values[field.name].length
        : 0
    }`
  );

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [clickUploadedFiles, setClickUploadedFiles] = useState([]);

  const getUploadUrl = (fileName, fileType) => {
    let folder = location.pathname.split("/")[1];

    // if (folder === "activities") {
    //   folder = "activities-data";
    // }

    if (["image/png", "image/jpeg"].includes(fileType)) {
      fileName = `${folder}/images/${fileName}`;
    } else if (["audio/mpeg", "audio/x-m4a", "audio/mp3"].includes(fileType)) {
      fileName = `${folder}/audio/${fileName}`;
    } else if (["video/quicktime", "video/mp4"].includes(fileType)) {
      fileName = `${folder}/video/${fileName}`;
    }

    fileName = encodeURIComponent(fileName.replace(/.([^.]*)$/, "%2E" + "$1"));
    return axios.get(`${fileUploadManagerApiUrl}file/${fileName}`);
  };

  const getFileUrl = (fileName) => {
    fileName = encodeURIComponent(
      fileName && fileName.replace(/.([^.]*)$/, "%2E" + "$1")
    );
    return axios.get(`${fileUploadManagerApiUrl}files/${fileName}`);
  };
  const getUploadedFiles = async () => {
    if (multiple) {
      values &&
        values[field.name].map(async (file) => {
          const response = await getFileUrl(file.fileName);
          setUploadedFiles((prv) => [
            ...prv,
            {
              fileName: file.fileName,
              url: response && response.data[0],
              fileType: file.fileType,
              name: file.name && file.name,
            },
          ]);
        });
    } else {
      if (indexValue) {
        if (field.name) {
          const response =
            fileNameAfterIndex &&
            fileNameAfterIndex.fileName &&
            (await getFileUrl(
              fileNameAfterIndex && fileNameAfterIndex.fileName
            ));
          setUploadedFiles([
            {
              // fileName: response.data[0],
              fileType: fileNameAfterIndex && fileNameAfterIndex.fileType,
              fileName: fileNameAfterIndex && fileNameAfterIndex.fileName,
              url:
                response && response.data && response.data[0]
                  ? response.data[0]
                  : "",
              // fileType: values && values[field.name].fileType,
              //
              name: fileNameAfterIndex && fileNameAfterIndex.name,
            },
          ]);
        } else {
        }
      } else {
        if (field.name && values[field.name] && values[field.name].fileName) {
          const response = await getFileUrl(
            field.name && values[field.name] && values[field.name].fileName
          );
          setUploadedFiles([
            {
              // fileName: response.data[0],
              fileType: values && values[field.name].fileType,
              fileName: values && values[field.name].fileName,
              url: response.data[0],
              // fileType: values && values[field.name].fileType,
              //
              name:
                values && values[field.name].name && values[field.name].name,
            },
          ]);
        }
      }
    }
  };
  useEffect(() => {
    getUploadedFiles();
  }, [field.name, id, values && values[field.name], indexValues]);

  useEffect(() => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      let nodes = [];
      let processedData = [];
      let initialArray = [];
      let idObject = {};

      uploadedFiles.map((res) => {
        if (res.fileName) {
          if (!idObject.hasOwnProperty(res.fileName)) {
            if (res.fileName) {
              idObject = {
                ...idObject,
                [res.fileName]: {
                  name: res.name,
                  fileName: res.fileName,
                  fileType: res.fileType,
                  url: res.url,
                },
              };
            }
          }
        }
      });
      initialArray = Object.values(idObject);
      initialArray.filter((filterRes) => {
        processedData.push({
          name: filterRes.name,
          fileName: filterRes.fileName,
          fileType: filterRes.fileType,
          url: filterRes.url,
        });
      });
      setClickUploadedFiles(processedData);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    getUploadedFiles();
  }, []);
  return (
    <>
      {label && <label>Enter {label}</label>}
      <SignedUploader
        key={label}
        beforeRequest={({ files }) =>
          new Promise(async (resolve, reject) => {
            // getUploadedFiles()
            const response = await getUploadUrl(files[0].name, files[0].type);
            if (response.error && response.error.message) {
              reject({ error: response.error.message });
            }
            resolve({
              fileName: response.data.fileName,
              fileType: files[0].type,
              url: response.data.url[0],
              name: files[0].name,
            });
          })
        }
        request={({ before, files }) => ({
          url: before.url,
          method: "PUT",
          headers: {
            "Content-Type": files[0].type,
          },
        })}
        afterRequest={({ before }) =>
          new Promise(async (resolve) => {
            const response = await getFileUrl(before.fileName);
            let uploadedFile = "";

            if (!response.error) {
              uploadedFile = response.data[0];
            }
            if (multiple) {
              setFieldValue(field.name, [
                ...(values && values[field.name]),
                {
                  fileName: before.fileName,
                  fileType: before.fileType,
                  name: before.name,
                  url: before.url,
                },
              ]);
              setUploadedFiles((res2) => [
                ...res2,
                {
                  fileName: before.fileName,
                  fileType: before.fileType,
                  url: uploadedFile,
                  name: before.name && before.name,
                },
              ]);
              setUploadFieldKey(
                `${label}-${values && values[field.name].length + 1}`
              );
            } else {
              if (indexValue) {
                // let newArrayImg = [];
                // newArrayImg.push(
                //   ...values[field.name],
                // )
                setFieldValue(field.name, {
                  fileName: before.fileName,
                  fileType: before.fileType,
                  name: before.name && before.name,
                  url: before.url,
                });
                setUploadedFiles([
                  {
                    url: uploadedFile,
                    fileType: before.fileType,
                    fileName: before.fileName,
                    name: before.name && before.name,
                  },
                ]);
                setUploadFieldKey(`${label}-${1}`);
              } else {
                setFieldValue(field.name, {
                  fileName: before.fileName,
                  fileType: before.fileType,
                  name: before.name && before.name,
                  url: uploadedFile,
                });
                setUploadedFiles([
                  {
                    url: uploadedFile,
                    fileType: before.fileType,
                    fileName: before.fileName,
                    name: before.name && before.name,
                  },
                ]);
                setUploadFieldKey(`${label}-${1}`);
              }
            }
            resolve("finished the upload!");
          })
        }
        uploadOnSelection={true}
        {...field}
        {...props}
      >
        {({ onFiles, progress, complete, canceled, failed }) => (
          <div className="uploader">
            <div
              style={{
                background: "#ffffff",
                height: "46px",
                padding: "0px",
              }}
              className={getFieldCSSClasses(
                touched[field.name],
                errors[field.name]
              )}
            >
              {disabled === false && (
                <UploadField
                  id="uploader"
                  name="uploader"
                  key={uploadFieldKey}
                  onFiles={onFiles}
                  uploadProps={{ accept }}
                  // className={getFieldCSSClasses(
                  //   touched[field.name],
                  //   errors[field.name]
                  // )}
                />
              )}
            </div>
            <div className="w-100 float-left">
              {progress && !complete && (
                <ProgressBar
                  now={progress}
                  label={`${progress}%`}
                  variant="info"
                  style={{ height: 20 }}
                />
              )}
              {/* {complete ? <Progress value={100} color="success" style={{height: 20}} /> : null} */}
              {canceled ? (
                <ProgressBar
                  now="100"
                  label="100%"
                  variant="warning"
                  style={{ height: 20 }}
                />
              ) : null}
              {failed ? (
                <ProgressBar
                  now="100"
                  label="100%"
                  variant="danger"
                  style={{ height: 20 }}
                />
              ) : null}
            </div>
            <div>
              {clickUploadedFiles.map(
                (uploadedFile, index) =>
                  uploadedFile && (
                    <div className="uploaded-file w-100" key={index}>
                      <div className="file">
                        {[
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                          "image/webp",
                        ].includes(uploadedFile.fileType) && (
                          <a
                            href={uploadedFile.url}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={index}
                          >
                            {showImage && (
                              <img
                                style={{ width: "100%" }}
                                src={uploadedFile.url}
                                alt="gjhgj"
                              />
                            )}

                            <p
                              className="m-1 text-dark px-2 py-1"
                              style={{
                                fontSize: "12px",
                                background: "#b3d1ff",
                                borderRadius: "5px",
                              }}
                            >
                              {uploadedFile.name
                                ? uploadedFile.name
                                : "no-name"}
                            </p>
                          </a>
                        )}
                        {["video/quicktime", "video/mp4"].includes(
                          uploadedFile.fileType
                        ) && (
                          <a
                            href={uploadedFile.fileName}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={index}
                          >
                            <video width="400" controls>
                              <source src={uploadedFile.url} type="video/mp4" />
                              <source src={uploadedFile.url} type="video/ogg" />
                              <source
                                src={uploadedFile.url}
                                type="video/quicktime"
                              />
                            </video>
                          </a>
                        )}
                        {uploadedFile.fileType === "text/plain" && (
                          <a
                            href={uploadedFile.fileName}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={index}
                          >
                            <img
                              src={toAbsoluteUrl("/media/svg/upload/txt.svg")}
                              alt=""
                            />
                          </a>
                        )}
                        {[
                          "application/x-zip-compressed",
                          "application/zip",
                        ].includes(uploadedFile.fileType) && (
                          <a
                            href={uploadedFile.fileName}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={index}
                          >
                            <img
                              src={toAbsoluteUrl("/media/svg/files/zip.svg")}
                              alt=""
                            />
                          </a>
                        )}
                        {["audio/mpeg", "audio/x-m4a", "audio/mp3"].includes(
                          uploadedFile.fileType
                        ) && (
                          <a
                            href={uploadedFile.fileName}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={index}
                          >
                            <audio controls>
                              <source src={uploadedFile.url} type="audio/ogg" />
                              <source
                                src={uploadedFile.url}
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio element.
                            </audio>
                            <p
                              className="m-0 text-dark px-2 py-1"
                              style={{
                                fontSize: "12px",
                                background: "#b3d1ff",
                              }}
                            >
                              {uploadedFile.name
                                ? uploadedFile.name
                                : "no-name"}
                            </p>
                          </a>
                        )}
                        {uploadedFile.fileType === "application/pdf" && (
                          <a
                            href={uploadedFile.fileName}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={index}
                          >
                            <img
                              src={toAbsoluteUrl("/media/svg/upload/pdf.svg")}
                              alt=""
                            />
                          </a>
                        )}
                        {uploadedFile.fileType === "application/msword" && (
                          <a
                            href={uploadedFile.fileName}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={index}
                          >
                            <img
                              src={toAbsoluteUrl("/media/svg/upload/doc.svg")}
                              alt=""
                            />
                          </a>
                        )}
                        {uploadedFile.fileType ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                          <a
                            href={uploadedFile.fileName}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={index}
                          >
                            <img
                              src={toAbsoluteUrl("/media/svg/upload/docx.svg")}
                              alt=""
                            />
                          </a>
                        )}
                      </div>
                      {showImage && (
                        <div className="link">
                          {disabled === false && (
                            <Button
                              type="button"
                              variant="outline-danger"
                              size="sm"
                              block
                              onClick={() => {
                                let afterFilterArray = clickUploadedFiles.filter(
                                  (resArray, indexOf) => {
                                    return (
                                      resArray.fileName !==
                                      uploadedFile.fileName
                                    );
                                  }
                                );
                                let valueField = [];
                                if (multiple) {
                                  valueField =
                                    values &&
                                    values[field.name].filter(
                                      (resArray, indexOf) => {
                                        return (
                                          resArray.fileName !==
                                          uploadedFile.fileName
                                        );
                                      }
                                    );
                                  setClickUploadedFiles(afterFilterArray);
                                  setUploadedFiles(afterFilterArray);
                                  setUploadFieldKey(
                                    `${label}-${values &&
                                      values[field.name].length - 1}`
                                  );
                                  setFieldValue(field.name, valueField);
                                } else {
                                  setUploadFieldKey(`${label}-${0}`);
                                  setClickUploadedFiles([]);
                                  setUploadedFiles([]);
                                  setFieldValue(field.name, "");
                                }
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </SignedUploader>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type="file"
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
