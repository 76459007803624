export const PageName = "User Happiness";
export const PageNameKey = "USER_HAPPINESS";
export const StateName = "userHappinesses";

export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const initialFilter = {
  filter: {},
  sortOrder: "asc", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 10,
};
export const EmotionType = [
  { label: "CONFUSED", value: "CONFUSED" },
  { label: "SAD", value: "SAD" },
  { label: "ANGRY", value: "ANGRY" },
  { label: "HAPPY", value: "HAPPY" },
  { label: "CALM", value: "CALM" },
  { label: "SURPRISED", value: "SURPRISED" },
  { label: "FEAR", value: "FEAR" },
  { label: "DISGUSTED", value: "DISGUSTED" },
];
export const UserEmotionType = [
  { label: "Happy", value: "Happy" },
  { label: "Sad", value: "Sad" },
  { label: "Excited", value: "Excited" },
  { label: "Content", value: "Content" },
  { label: "Stressed", value: "Stressed" },
  { label: "Bored", value: "Bored" },
];
