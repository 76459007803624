/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as actions from "../_redux/actions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

import { Gender } from "./ItemsUIHelpers";
import { Input } from "../../../../_metronic/_partials/controls";
import { Textarea } from "../../../../_component/form/Textarea";
import { Select } from "../../../../_metronic/_partials/controls";
import { format } from "date-fns";
import { File } from "../../../../_component/form/File";

const initialValues = {
  name: "",
  gender: "",
  dateOfBirth: format(new Date(), "yyyy-MM-dd"),
  country: "",
  bio: "",
  profileImg: {},
  latitude: "",
  longitude: "",
  isPurchase: false,
  explanationSeen: false,
  lifeScore: "",
  mindScore: "",
  bodyScore: "",
  initialLifeScore: "",
  initialMindScore: "",
  initialBodyScore: "",
  healthScore: "",
  nutritionScore: "",
  lifeStyleScore: "",
  joyscore: "",
};

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  gender: Yup.string().required("This field is required"),
  dateOfBirth: Yup.string().required("This field is required"),
  country: Yup.string().required("This field is required"),
  bio: Yup.string().required("This field is required"),
  profileImg: Yup.string().required("This field is required"),
  latitude: Yup.string().required("This field is required"),
  longitude: Yup.string().required("This field is required"),
  isPurchase: Yup.string().required("This field is required"),
  explanationSeen: Yup.string().required("This field is required"),
  lifeScore: Yup.string().required("This field is required"),
  mindScore: Yup.string().required("This field is required"),
  bodyScore: Yup.string().required("This field is required"),
  initialLifeScore: Yup.string().required("This field is required"),
  initialMindScore: Yup.string().required("This field is required"),
  initialBodyScore: Yup.string().required("This field is required"),
  healthScore: Yup.string().required("This field is required"),
  nutritionScore: Yup.string().required("This field is required"),
  lifeStyleScore: Yup.string().required("This field is required"),
  joyscore: Yup.string().required("This field is required"),
});

export default function ({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, data } = useSelector(
    (state) => ({
      actionsLoading: state.people.actionsLoading,
      data: state.people.data,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchItem(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Person";

    if (data && id) {
      _title = "Edit Person";
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id]);

  const savePerson = (values) => {
    if (!id) {
      dispatch(actions.createItem(values)).then(() => backToList());
    } else {
      dispatch(actions.updateItem(values)).then(() => backToList());
    }
  };

  const btnRef = useRef();
  const saveClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToList = () => {
    history.push("/people");
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button type="button" onClick={backToList} className="btn btn-light">
            <i className="fa fa-arrow-left"></i>
            Back
          </button>

          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveClick}
          >
            Save
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            enableReinitialize={true}
            initialValues={data || initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              savePerson(values);
            }}
          >
            {({ handleSubmit, values, handleChange, setFieldValue }) => (
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-4 mb-5">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Select name="gender" label="Gender" multiple={false}>
                      <option value="">Select</option>
                      {Gender.map((item) => (
                        <option
                          key={item["label"] + "-" + item["value"]}
                          value={item["value"]}
                        >
                          {item["label"]}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="date"
                      name="dateOfBirth"
                      component={Input}
                      placeholder="Date Of Birth"
                      label="Date Of Birth"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      name="country"
                      component={Input}
                      placeholder="Country"
                      label="Country"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      as="textarea"
                      name="bio"
                      component={Textarea}
                      placeholder="Bio"
                      label="Bio"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="file"
                      name="profileImg"
                      component={File}
                      id={id}
                      placeholder="Profile Img"
                      label="Profile Img"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      name="latitude"
                      component={Input}
                      placeholder="Latitude"
                      label="Latitude"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      name="longitude"
                      component={Input}
                      placeholder="Longitude"
                      label="Longitude"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <label htmlFor="isPurchase">Is Purchase</label>
                    <div>
                      <span className="switch switch-sm">
                        <label>
                          <input
                            type="checkbox"
                            name="isPurchase"
                            checked={values.isPurchase}
                            onChange={handleChange}
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5">
                    <label htmlFor="explanationSeen">Explanation Seen</label>
                    <div>
                      <span className="switch switch-sm">
                        <label>
                          <input
                            type="checkbox"
                            name="explanationSeen"
                            checked={values.explanationSeen}
                            onChange={handleChange}
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="lifeScore"
                      component={Input}
                      placeholder="Life Score"
                      label="Life Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="mindScore"
                      component={Input}
                      placeholder="Mind Score"
                      label="Mind Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="bodyScore"
                      component={Input}
                      placeholder="Body Score"
                      label="Body Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="initialLifeScore"
                      component={Input}
                      placeholder="Initial Life Score"
                      label="Initial Life Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="initialMindScore"
                      component={Input}
                      placeholder="Initial Mind Score"
                      label="Initial Mind Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="initialBodyScore"
                      component={Input}
                      placeholder="Initial Body Score"
                      label="Initial Body Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="healthScore"
                      component={Input}
                      placeholder="Health Score"
                      label="Health Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="nutritionScore"
                      component={Input}
                      placeholder="Nutrition Score"
                      label="Nutrition Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="lifeStyleScore"
                      component={Input}
                      placeholder="Life Style Score"
                      label="Life Style Score"
                    />
                  </div>
                  <div className="col-lg-4 mb-5">
                    <Field
                      type="number"
                      name="joyscore"
                      component={Input}
                      placeholder="JoyScore"
                      label="JoyScore"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={() => handleSubmit()}
                ></button>
              </Form>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
}
