import React from "react";
import Modal from "react-bootstrap/Modal";

const Model = ({ children, headerTitle,showCardHeader=true, ...props }) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {showCardHeader&&<Modal.Header className="h5">{headerTitle}</Modal.Header>}
      <Modal.Body className="p-0">{children}</Modal.Body>
    </Modal>
  );
};

export default Model;
