import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import UserPage from "./modules/Users/pages/index";

const AssessmentTypesPage = lazy(() =>
  import("./modules/AssessmentTypes/pages/index")
);
const AssessmentsPage = lazy(() => import("./modules/Assessments/pages/index"));
const UserAssessmentRecordsPage = lazy(() =>
  import("./modules/UserAssessmentRecords/pages/index")
);
const UserScoresPage = lazy(() => import("./modules/UserScores/pages/index"));
const AppsPage = lazy(() => import("./modules/Apps/pages/index"));
const SubCategoriesPage = lazy(() =>
  import("./modules/SubCategories/pages/index")
);
const ActivityTypesPage = lazy(() =>
  import("./modules/ActivityTypes/pages/index")
);
const ContentTypesPage = lazy(() =>
  import("./modules/ContentType/pages/index")
);
// const ContentPage = lazy(()=>
// import("./modules/Content/pages/index")
// )
const TopicsPage = lazy(() => import("./modules/Topics/pages/index"));
const CommentsPage = lazy(() => import("./modules/Comments/pages/index"));
const BlockedCommentsPage = lazy(() =>
  import("./modules/BlockedComments/pages/index")
);
const AvatarAssetTypesPage = lazy(() =>
  import("./modules/AvatarAssetTypes/pages/index")
);
const SupportedColorsPage = lazy(() =>
  import("./modules/SupportedColors/pages/index")
);
const ActivitiesPage = lazy(() => import("./modules/Activities/pages/index"));
const UserAvatarsPage = lazy(() => import("./modules/UserAvatars/pages/index"));
const UserActivityJourneyPage = lazy(() =>
  import("./modules/UserActivityJourney/pages/index")
);
const CustomActivityPathsPage = lazy(() =>
  import("./modules/CustomActivityPaths/pages/index")
);
const UserCustomActivityPathsPage = lazy(() =>
  import("./modules/UserCustomActivityPaths/pages/index")
);
const AchivementsPage = lazy(() => import("./modules/Achivements/pages/index"));
const UserAchivementsPage = lazy(() =>
  import("./modules/UserAchivements/pages/index")
);
const CategoriesPage = lazy(() => import("./modules/Categories/pages/index"));
const UsersPage = lazy(() => import("./modules/Users/pages/index"));
const ContentPage = lazy(() => import("./modules/Content/pages/index"));
const LifeTracker = lazy(() => import("./modules/LifeTrackers/pages/index"));
const People = lazy(() => import("./modules/People/pages/index"));
const UserHappiness = lazy(() =>
  import("./modules/UserHappinesses/pages/index")
);
const UserMoodTrackers = lazy(() =>
  import("./modules/UserMoodTrackers/pages/index")
);
const UserReferralCodes = lazy(() =>
  import("./modules/UserReferralCodes/pages/index")
);
const UserStresses = lazy(() => import("./modules/UserStresses/pages/index"));
const UserTimeSpentLogs = lazy(() =>
  import("./modules/UserTimeSpentLogs/pages/index")
);
const AuthProviders = lazy(() => import("./modules/AuthProviders/pages/index"));
const UserAuthProviders = lazy(() =>
  import("./modules/UserAuthProviders/pages/index")
);
const ProductsPage = lazy(() => import("./modules/Products/pages/index"));
const CacheManagementPage = lazy(() =>
  import("./modules/CacheManagement/pages/index")
);
const ProductRatingsPage = lazy(() =>
  import("./modules/ProductRatings/pages/index")
);
const NotificationTypesPage = lazy(() =>
  import("./modules/NotificationTypes/pages/index")
);

const NotificationTemplatesPage = lazy(() =>
  import("./modules/NotificationTemplate/pages/index")
);

const UserProfilesPage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const RolePage = lazy(() => import("./modules/Roles/pages/index"));
const ModulePage = lazy(() => import("./modules/Modules/pages/index"));
const PermissionTypePage = lazy(() =>
  import("./modules/PermissionTypes/pages/index")
);
const PermissionLevelPage = lazy(() =>
  import("./modules/PermissionLevels/pages/index")
);
const RolePermissionsPage = lazy(() =>
  import("./modules/Permissions/pages/index")
);
const UserRolesPage = lazy(() => import("./modules/UserRoles/pages/index"));
const OrganizationsPage = lazy(() =>
  import("./modules/Organization/pages/index")
);
const GamesPage = lazy(() => import("./modules/Games/pages/index"));
const SpiritualsPage = lazy(() => import("./modules/Spirituals/pages/index"));
const NewsPage = lazy(() => import("./modules/News/pages/index"));
const NewsCategoryPage = lazy(() =>
  import("./modules/NewsCategory/pages/index")
);
const SpiritualsVideoPage = lazy(() =>
  import("./modules/SpiritualsVideos/pages/index")
);
const AppFeedbacksPage = lazy(() =>
  import("./modules/AppFeedback/pages/index")
);
const OrganizationUser = lazy(() =>
  import("./modules/OrganizationUser/pages/index")
);
const UserProfilePage = lazy(() => import("./modules/Profile/pages/index"));

const InfluencerList = lazy(() =>
  import("./modules/InfluencerList/pages/index")
);
const OrganizationList = lazy(() =>
  import("./modules/OrganizationList/pages/index")
);
const BlogFeaturesPage = lazy(() =>
  import("./modules/BlogFeature/pages/index")
);
const PageFeaturesPage = lazy(() =>
  import("./modules/PageFeature/pages/index")
);
const AchivementPage = lazy(() => import("./modules/Achivements/pages/index"));
const BadgePage = lazy(() => import("./modules/Badge/pages/index"));
export default function BasePage() {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  useEffect(() => {
    localStorage.setItem("isLoggedIn", false);
  }, []);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />

        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/organization-user" component={OrganizationUser} />
        <ContentRoute
          path="/assessment-types"
          component={AssessmentTypesPage}
        />
        <ContentRoute path="/assessments" component={AssessmentsPage} />
        <ContentRoute
          path="/user-assessment-records"
          component={UserAssessmentRecordsPage}
        />
        <ContentRoute path="/user-scores" component={UserScoresPage} />
        <ContentRoute path="/apps" component={AppsPage} />
        <ContentRoute path="/sub-categories" component={SubCategoriesPage} />
        <ContentRoute path="/activity-types" component={ActivityTypesPage} />
        <ContentRoute path="/content-types" component={ContentTypesPage} />
        <ContentRoute path="/topics" component={TopicsPage} />
        <ContentRoute path="/comments" component={CommentsPage} />
        <ContentRoute
          path="/blocked-comments"
          component={BlockedCommentsPage}
        />
        <ContentRoute
          path="/avatar-asset-types"
          component={AvatarAssetTypesPage}
        />
        <ContentRoute
          path="/supported-colors"
          component={SupportedColorsPage}
        />
        <ContentRoute path="/activities" component={ActivitiesPage} />
        <ContentRoute path="/user-avatars" component={UserAvatarsPage} />
        <ContentRoute
          path="/user-activity-journey"
          component={UserActivityJourneyPage}
        />
        <ContentRoute
          path="/custom-activity-paths"
          component={CustomActivityPathsPage}
        />
        <ContentRoute
          path="/user-custom-activity-paths"
          component={UserCustomActivityPathsPage}
        />
        <ContentRoute path="/achivements" component={AchivementsPage} />
        <ContentRoute
          path="/user-achivements"
          component={UserAchivementsPage}
        />
        <ContentRoute path="/categories" component={CategoriesPage} />
        <ContentRoute path="/users" component={UsersPage} />
        <ContentRoute path="/content" component={ContentPage} />
        <ContentRoute path="/life-trackers" component={LifeTracker} />
        <ContentRoute path="/people" component={People} />
        <ContentRoute path="/user-happinesses" component={UserHappiness} />
        <ContentRoute path="/user-mood-trackers" component={UserMoodTrackers} />
        <ContentRoute
          path="/user-referral-codes"
          component={UserReferralCodes}
        />
        <ContentRoute path="/user-stresses" component={UserStresses} />
        <ContentRoute
          path="/user-time-spent-logs"
          component={UserTimeSpentLogs}
        />
        <ContentRoute path="/auth-providers" component={AuthProviders} />
        <ContentRoute
          path="/user-auth-providers"
          component={UserAuthProviders}
        />
        <ContentRoute path="/products" component={ProductsPage} />
        <ContentRoute path="/product-ratings" component={ProductRatingsPage} />
        <ContentRoute
          path="/notification-types"
          component={NotificationTypesPage}
        />
        <ContentRoute
          path="/notification-templates"
          component={NotificationTemplatesPage}
        />
        <ContentRoute
          path="/cache-management"
          component={CacheManagementPage}
        />
        <ContentRoute path="/user-profile" component={UserProfilesPage} />
        <ContentRoute path="/roles" component={RolePage} />
        <ContentRoute path="/modules" component={ModulePage} />
        <ContentRoute path="/permission-types" component={PermissionTypePage} />
        <ContentRoute
          path="/permission-levels"
          component={PermissionLevelPage}
        />
        <ContentRoute
          path="/role-permissions"
          component={RolePermissionsPage}
        />
        <ContentRoute path="/user-roles" component={UserRolesPage} />
        <ContentRoute path="/organizations" component={OrganizationsPage} />
        <ContentRoute path="/games" component={GamesPage} />
        <ContentRoute path="/spirituals-people" component={SpiritualsPage} />
        <ContentRoute path="/news" component={NewsPage} />
        <ContentRoute path="/news-category" component={NewsCategoryPage} />
        <ContentRoute path="/app-feedbacks" component={AppFeedbacksPage} />
        <ContentRoute
          path="/spirituals-videos"
          component={SpiritualsVideoPage}
        />
        <ContentRoute path="/profile" component={UserProfilePage} />
        <ContentRoute path="/organization-list" component={OrganizationList} />
        <ContentRoute path="/influencer-list" component={InfluencerList} />
        <ContentRoute path="/blogs" component={BlogFeaturesPage} />
        <ContentRoute path="/page" component={PageFeaturesPage} />
        <ContentRoute path="/achivement" component={AchivementPage} />
        <ContentRoute path="/badge" component={BadgePage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
