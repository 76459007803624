export const PageName = "Person";
export const PageNameKey = "PERSON";
export const StateName = "people";
export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const initialFilter = {
  filter: {},
  sortOrder: "asc", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 10,
};
export const Gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];
