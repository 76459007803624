// / eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props 
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as actions from "../_redux/actions";
// import * as roleActions from "../../Roles/_redux/actions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { MOBILE_NUMBER_REGEX } from "../../../../_component/constant.js"
import { Input } from "../../../../_metronic/_partials/controls";
// import { Gender } from "./ItemsUIHelpers";
// import { Select } from "../../../../_metronic/_partials/controls";


// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email()
    .required("This field is required"),
  countryCode: Yup.string().required("This field is required"),
  mobileNo: Yup.string()
    .matches(MOBILE_NUMBER_REGEX, { message: 'Enter valid phone number' })
    .required('Enter your mobile number'),
  personId: Yup.string().required("This field is required")
});

export default function ({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();


  // Tabs
  const [title, setTitle] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    // role: [],
    email: "",
    countryCode: "",
    mobileNo: "",
    personId: ""
  })


  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, data } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      data: state.users.data,
      // roles: state.users.roleList,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchItem(id));
    // dispatch(actions.getRoleList());
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New User";

    if (data && id) {
      _title = "Edit User";
    }
console.log(data)
    if (id && data) {
      setInitialValues({
        id,
        name: data.name?data.name:data.data.person.name,
        email: data.email?data.email:data.data.person.email,
        countryCode: data.countryCode?data.countryCode:data.data.person.countryCode,
        mobileNo: data.mobileNo?data.mobileNo:data.data.person.mobileNo,
        personId: data.personId,
      })

    }
    //  else {
    //   setInitialValues({
    //     name: "",
    //     personId: "",
    //     // age: "",
    //     // gender: "",
    //     //  mobileNumber:"",
    //   });
    // }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id]);

  const saveUser = (values) => {

    if (!id) {
      dispatch(actions.createItem(values)).then(() => backToList());
    } else {
      dispatch(actions.updateItem(values)).then(() => backToList());
    }
  };

  const btnRef = useRef();
  const saveClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }

  };

  const backToList = () => {
    history.push("/users");
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button type="button" onClick={backToList} className="btn btn-light">
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveClick}
          >
            Save
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {

              saveUser(values);
            }}
          >
            {({ handleSubmit, values, handleChange, setFieldValue }) => (
              <Form className="form form-label-right"> 
                <div className="form-group row">
                  {/* <div className="col-lg-4 col-md-6 mb-5">
                    <Select name="role" label="User Role" multiple={true}>
                      <option value="">Select</option>
                      {roles &&
                        roles.map((role) => (
                          <option key={role.id} value={role.title}>
                            {role.title}
                          </option>
                        ))}
                    </Select>
                  </div> */}
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                    />
                  </div>
                  {/* <div className="col-lg-4 col-md-6 mb-5">
                    <Field
                      type="number"
                      name="age"
                      component={Input}
                      placeholder="Age"
                      label="Age"
                    />
                  </div> */}
                  {/* <div className="col-lg-4 col-md-6 mb-5">
                    <Select name="gender" label="Gender" multiple={false}>
                      <option value="">Select</option>
                      {Gender.map((item) => (
                        <option
                          key={item["label"] + "-" + item["value"]}
                          value={item["value"]}
                        >
                          {item["label"]}
                        </option>
                      ))}
                    </Select>
                  </div> */}
                  <div className="col-lg-4 col-md-6 mb-5">
                   {id? <Field
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                      disabled={true}
                    />:<Field
                    name="email"
                    component={Input}
                    placeholder="Email"
                    label="Email"
                  />}
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Field
                      name="countryCode"
                      component={Input}
                      placeholder="Country Code"
                      label="Country Code"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Field
                      name="mobileNo"
                      component={Input}
                      placeholder="Mobile Number"
                      label="Mobile Number"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                   {id?<Field
                      disabled={true}
                      name="personId"
                      component={Input}
                      placeholder="Person ID"
                      label="Person ID"
                    />:
                    <Field
                    name="personId"
                    component={Input}
                    placeholder="Person ID"
                    label="Person ID"
                  />
                    }
                  </div>

                </div>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={() => handleSubmit()}
                ></button>
              </Form>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
}
