import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../_metronic/layout";
import { ProfileCard } from "./components/ProfileCard";
import LifeTrackerList from "../LifeTrackers/pages/index";
import UserTimeSpent from '../UserTimeSpentLogs/pages/index';
import UserMoodTracker from '../UserMoodTrackers/pages/index';
import UserHappiness from '../UserHappinesses/pages/index';
import UserStress from '../UserStresses/pages/index';
import Person from '../People/pages/Form';
// import AccountInformation from "./AccountInformation";
// import { ProfileOverview } from "./ProfileOverview";
// import ChangePassword from "./ChangePassword";
// import PersonaInformation from "./PersonaInformation";
// import EmailSettings from "./EmailSettings";
// import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";

export default function UserProfilePage() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("User profile");

  return (
    <>
      <div className="d-flex flex-row">
        <ProfileCard></ProfileCard>
        <div className="flex-row-fluid ml-lg-8">
          <Switch>
            <Redirect from="/user-profile/" exact={true} to="/user-profile" />
            <ContentRoute path="/users/life-tracker" component={LifeTrackerList} />
            <ContentRoute
              path="/users/user-mood-tracker"
              component={UserMoodTracker}
            />
            {/* <ContentRoute path="/joybiome" element={<Products appName="joybiome" />} /> */}
            <ContentRoute
              path="/users/user-time-spent"
              component={UserTimeSpent}
            />
            {/* <ContentRoute path="/users/user-mood-trackers" component={List} /> */}
            <ContentRoute path="/users/user-stress" component={UserStress} />
            <ContentRoute path="/users/user-happiness" component={UserHappiness} />
            <ContentRoute path="/users/person" component={Person} />
            
          </Switch>
        </div>
      </div>
    </>
  );
}
