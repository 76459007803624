import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { TopActions } from "../subheader/components/TopActions";

export function Brand() {
  return (
    <>
      {/* begin::Brand */}
      <div
        className="aside-brand d-none d-lg-flex justify-content-between pl-3 py-3 py-lg-8 border-bottom position-fixed w-100 bg-white"
        style={{ zIndex: 99 }}
      >
        <div className="flex-grow-1">
          {/* begin::Logo */}
          <Link to="" className="brand-logo">
            <img alt="logo" src={toAbsoluteUrl("/media/logos/joy-score.png")} />
          </Link>
          {/* end::Logo */}
        </div>
        <TopActions />
      </div>
      {/* end::Brand */}
    </>
  );
}
