import React from "react";
import { useSelector } from "react-redux";
import * as actions from "../_redux/actions";
import * as uiHelpers from "./ItemsUIHelpers";
import { ItemsList } from "../../../../_component/list/ItemsList";
import { sortCaret } from "../../../../_metronic/_helpers";

export default (props) => {
  const { userRoles } = useSelector(({ auth }) => ({
    userRoles: auth.userRoles ? auth.userRoles : null,
  }));

  return (
    <ItemsList
      {...props}
      actions={actions}
      uiHelpers={uiHelpers}
      userRoles={userRoles}
      showAddButton={true}
      columns={[
        {
          dataField: "title",
          text: "Title",
          sort: true,
          sortCaret,
        },
        {
          dataField: "question",
          text: "Question",
          sort: true,
          sortCaret,
        },
        {
          dataField: "age",
          text: "Age",
          sort: true,
          sortCaret,
        },
        {
          dataField: "answer",
          text: "Answer",
          sort: true,
          sortCaret,
        },
        {
          dataField: "mood",
          text: "Mood",
          sort: true,
          sortCaret,
        },
        {
          dataField: "intensity",
          text: "Intensity",
          sort: true,
          sortCaret,
        },
      ]}
    />
  );
};
